import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import type { IDeepBoxError } from '@/api/types/error';

export const useErrorStore = defineStore('error', () => {
  const error = ref<Error | IDeepBoxError>();

  function create(e: Error | IDeepBoxError) {
    error.value = e;
  }

  return {
    error,
    // actions
    create
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useErrorStore, import.meta.hot));
}
