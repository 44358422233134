export const ONBOARDING_LINKS = {
  ONBOARDING_LINK_ACCOUNT_VERIFY: `${import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL}account/verify`,
  ONBOARDING_LINK_ACCOUNT: `${import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL}account`,
  ONBOARDING_LINK_ACCOUNT_ORGANIZATION: `${
    import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL
  }account/organization`,
  ONBOARDING_LINK_ACCOUNT_PRIVATE: `${
    import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL
  }account/private`,
  ONBOARDING_LINK_REGISTER: `${import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL}register`
};

export default {
  ONBOARDING_LINKS
};
