import { deepBoxApi } from '@/api/interceptors';
import type { HttpClientResponse, HttpClient } from '@/api/http-client';
import type { NodeTagsUpdate } from '@/api/types/deepbox/node';

export default class DeepBoxNodesTagsUpdateAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  patchById(
    nodeId: string,
    data: NodeTagsUpdate
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.put<never>(`nodes/${nodeId}/tags/update`, data);
  }
}

export const deepBoxNodesTagsUpdateAPI = new DeepBoxNodesTagsUpdateAPIService(
  deepBoxApi
);
