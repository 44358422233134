import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { NodeInfo } from '@/api/types/deepbox/node';

export default class DeepBoxNodesInfoAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<NodeInfo>> {
    return this.httpClient.get<NodeInfo>(
      `nodes/${nodeId}/info`,
      params,
      options
    );
  }
}

export const deepBoxNodesInfoAPI = new DeepBoxNodesInfoAPIService(deepBoxApi);
