<template>
  <v-card class="px-2" flat>
    <v-textarea
      ref="textareaRef"
      v-model="model"
      v-bind="$attrs"
      :rows="Number(rows) > 0 ? rows : isCommentFieldFocused ? 4 : 1"
      density="compact"
      :bg-color="isCommentFieldFocused ? 'transparent' : 'grey-lighten-3'"
      :placeholder="defaultPlaceholder"
      clear-icon="fas fa-circle-xmark"
      variant="outlined"
      clearable
      style="font-size: 13px"
      name="field-comment"
      hide-details="auto"
      @focus="isCommentFieldFocused = true"
      @blur="onBlur"
      @keydown.stop="onCommentKeydown"
      @keyup.stop
    />
    <v-card-actions v-show="isCommentFieldFocused" class="px-0 mx-0">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { VTextarea } from 'vuetify/components';
import { DeviceKey } from '@/plugins/device-detector-js.ts';

const props = defineProps({
  rows: {
    type: [Number, String],
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: null
  },
  editMode: {
    type: Boolean,
    default: false
  }
});

const model = defineModel({ type: String, default: '' });
const emit = defineEmits(['comment:add', 'is-field-focused']);

const device = inject(DeviceKey);

const { t } = useI18n();

const isCommentFieldFocused = ref(false);

const defaultPlaceholder = computed(() => {
  if (props.placeholder) {
    return props.placeholder;
  }
  return t('core.comments.placeholder_add_comment');
});

watch(
  () => isCommentFieldFocused.value,
  (newValue) => {
    emit('is-field-focused', newValue);
  }
);

function onCommentAdd() {
  emit('comment:add');
  blur();
}

function onCommentKeydown(e) {
  if (device?.isMobile) return;
  if (
    e.keyCode === 13 &&
    !e.altKey &&
    !e.shiftKey &&
    !e.ctrlKey &&
    !e.metaKey
  ) {
    onCommentAdd();
  }
}

const textareaRef = ref<typeof VTextarea | null>(null);

function blur() {
  const textareaEl = textareaRef.value?.$el.querySelector('textarea');
  if (textareaEl) {
    textareaEl.blur();
    isCommentFieldFocused.value = false;
  }
}

function onBlur() {
  if (!props.editMode && !model.value) {
    isCommentFieldFocused.value = !isCommentFieldFocused.value;
  }
}

function clear() {
  model.value = '';
  isCommentFieldFocused.value = false;
}

defineExpose({ clear, blur });
</script>

<style scoped lang="scss">
:deep(.v-textarea) textarea {
  font-size: 13px;
}
</style>
