import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';

interface Snackbar {
  key: string;
  active: boolean;
  top: boolean;
  color: string;
  text: string;
  timeout: number;
  icon: string;
}

const defaultSnackbarState = {
  key: '',
  active: false,
  top: true,
  color: 'success',
  text: '',
  timeout: 6000,
  icon: ''
};

export const useMessagesStore = defineStore('messages', () => {
  const snackbar = ref<Snackbar>({
    ...defaultSnackbarState
  });
  const numVisibleSnackbars = ref(0);

  function showMessage(data: Snackbar) {
    Object.assign(snackbar.value, { ...data, active: true });
    setTimeout(() => {
      hideMessage();
    }, 3500);
  }

  function hideMessage() {
    snackbar.value = {
      ...defaultSnackbarState
    };
  }

  function showMessageValidateError() {
    const data = { key: 'form_validation_error', color: 'error' };
    showMessage(<Snackbar>data);
  }

  function onSnackbarRemoved() {
    numVisibleSnackbars.value -= 1;
  }

  function onSnackbarAdded() {
    numVisibleSnackbars.value += 1;
  }

  return {
    // state
    snackbar,
    numVisibleSnackbars,
    // actions
    showMessage,
    hideMessage,
    showMessageValidateError,
    onSnackbarRemoved,
    onSnackbarAdded
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMessagesStore, import.meta.hot));
}
