import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type {
  ListTagsResult,
  TagsGetQueryParams
} from '@/api/types/deepbox/tag';

export default class DeepBoxDeepBoxesBoxesTagsAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: TagsGetQueryParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<ListTagsResult>> {
    return this.httpClient.get<ListTagsResult>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/tags`,
      params,
      options
    );
  }
}

export const deepBoxDeepBoxesBoxesTagsAPI =
  new DeepBoxDeepBoxesBoxesTagsAPIService(deepBoxApi);
