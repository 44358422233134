import type { BoxInfo, BoxType } from '@/api/types/deepbox/box';
import type { ComputedRef } from 'vue';

export function useBoxInfo(box: ComputedRef<BoxInfo>) {
  function hasBoxRelationByBoxType(boxType: BoxType) {
    return box.value.relations.filter((a) => a.boxType === boxType).length > 0;
  }

  return {
    hasBoxRelationByBoxType
  };
}
