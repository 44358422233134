<template>
  <v-card
    class="mx-auto"
    flat
    style="background-color: transparent"
    v-bind="$attrs"
  >
    <v-card-title
      class="d-flex align-center"
      :style="expandBtnDisabled ? '' : { cursor: 'pointer' }"
      @click.stop="expandBtnDisabled ? undefined : onChangeExpandCard()"
    >
      <slot name="title">
        <div>
          {{ title }}
        </div>
        <v-spacer />
      </slot>
      <v-btn
        v-if="!expandBtnHidden && !expandBtnDisabled"
        icon
        :size="expandBtnSize"
        slim
        :data-test-id="model ? 'collapse' : 'expand'"
        @click.stop="onChangeExpandCard"
      >
        <v-icon :size="expandBtnIconSize" :color="expandBtnIconColor">
          {{ model ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider v-if="showDivider" />
    <v-expand-transition :mode="expandMode">
      <div v-if="model">
        <v-card-text class="py-0">
          <slot name="content"></slot>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    default: null
  },
  expandMode: {
    type: String,
    default: 'ease-in-out'
  },
  showDivider: {
    type: Boolean,
    default: false
  },
  expandBtnIconSize: {
    type: String,
    default: 'small'
  },
  expandBtnIconColor: {
    type: String,
    default: 'grey'
  },
  expandBtnSize: {
    type: String,
    default: 'x-small'
  },
  expandBtnDisabled: {
    type: Boolean,
    default: false
  },
  expandBtnHidden: {
    type: Boolean,
    default: false
  }
});

const model = defineModel({ type: Boolean, default: false });

function onChangeExpandCard() {
  model.value = !model.value;
}
</script>
