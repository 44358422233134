import { deepOApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { DeepOQueryParams } from '@/api/types/deepo/query-params';
import type { GetTasksResponse } from '@/api/types/deepo/peng-task';

export default class DeepOPengTasksAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  getByCurrentUser(
    params?: DeepOQueryParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<GetTasksResponse>> {
    return this.httpClient.get<GetTasksResponse>(
      '/peng/v1/runtime/current-user',
      params,
      options
    );
  }
}

export const deepOPengTasksAPIService = new DeepOPengTasksAPIService(deepOApi);
