import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { Tag } from '@/api/types/deepbox/tag';
import type { NodeTagsUpdate } from '@/api/types/deepbox/node';

export default class DeepBoxNodesTagsAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<Tag[]>> {
    return this.httpClient.get<Tag[]>(`nodes/${nodeId}/tags`, params, options);
  }

  patchById(
    nodeId: string,
    data: string[]
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.put<never>(`nodes/${nodeId}/tags`, data);
  }

  updateById(
    nodeId: string,
    data: NodeTagsUpdate,
    config?: Record<string, unknown>
  ): Promise<HttpClientResponse<Tag[]>> {
    return this.httpClient.put<Tag[]>(
      `nodes/${nodeId}/tags/update`,
      data,
      config
    );
  }
}

export const deepBoxNodesTagsAPI = new DeepBoxNodesTagsAPIService(deepBoxApi);
