import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { NodeDownloadUrlQueryParams } from '@/api/types/deepbox/node-download-url';

export default class DeepBoxNodesDownloadUrlAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    nodeId: string,
    params?: NodeDownloadUrlQueryParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<string>> {
    return this.httpClient.get<string>(
      `nodes/${nodeId}/downloadUrl`,
      params,
      options
    );
  }
}

export const deepBoxNodesDownloadUrlAPI = new DeepBoxNodesDownloadUrlAPIService(
  deepBoxApi
);
