<template>
  <div
    class="preview-btn-fab-actions"
    :class="{ 'no-drawer': !globalStore.boxDetailsDrawer }"
  >
    <PreviewBtnFab
      v-if="!globalStore.boxDetailsDrawer"
      v-tooltip.start="t('node_data_table.actions.show_details')"
      @click="globalStore.boxDetailsDrawer = !globalStore.boxDetailsDrawer"
    >
      <v-icon>far fa-circle-info</v-icon>
    </PreviewBtnFab>
    <PreviewBtnFab
      v-if="showDeepSignBtn"
      v-tooltip.start="t('node_data_table.actions.sign')"
      data-test-id="deepSign"
      @click="openDeepSignOrConvertNode"
    >
      <v-icon>
        <img src="/svg/deepsign/sign_Icon_24.svg" alt="DeepSign" />
      </v-icon>
    </PreviewBtnFab>
    <PreviewBtnFab
      v-if="showEditBtn"
      v-tooltip.start="t('node_data_table.actions.edit')"
      data-test-id="edit-document"
      @click="onEditNode"
    >
      <v-icon>far fa-pen</v-icon>
    </PreviewBtnFab>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType, ref, watch } from 'vue';
import PreviewBtnFab from '@/components/preview/PreviewBtnFab.vue';
import { constants } from '@/constants/constants';

import { useGlobalStore } from '@/stores/global/global';
import { useNodePolicy } from '@/composables/use-node-policy';
import { useDeepSign } from '@/composables/use-deep-sign';
import { useRouter } from 'vue-router';
import type { PathSegment } from '@/api/types/deepbox/path';
import type { Node } from '@/api/types/deepbox/node';
import { useNodeConvertPdfAndSign } from '@/composables/use-node-convert-pdf-and-sign';
import { isOnlyOfficeEnabled } from '@/utils/helpers/features.ts';
import {
  isNodeMimeTypeDOCX,
  isNodeMimeTypeXLSX,
  isNodePdf
} from '@/utils/helpers/nodes.ts';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  node: {
    type: Object as PropType<Node | PathSegment>,
    default: () => ({})
  }
});

const router = useRouter();
const { t } = useI18n();

const globalStore = useGlobalStore();

const canOpenDocumentOnDeepSign = ref(false);

const { canRevision, canSign } = useNodePolicy();

const isPdf = computed(() => isNodePdf(props.node));

const showDeepSignBtn = computed(
  () =>
    (isPdf.value && canSign(props.node) && canOpenDocumentOnDeepSign.value) ||
    isNodeMimeTypeDOCX(props.node) ||
    isNodeMimeTypeXLSX(props.node)
);

const showEditBtn = computed(() => {
  const supportedMimeType = constants.FILE_EDIT_SUPPORTED_MIME_TYPES.includes(
    props.node?.mimeType
  );
  return canRevision(props.node) && supportedMimeType && isOnlyOfficeEnabled();
});

const {
  openDeepSignDocument,
  canOpenDocumentOnDeepsign: onCanOpenDocumentOnDeepsign
} = useDeepSign();

const nodeConvertPdfAndSign = useNodeConvertPdfAndSign();

function openDeepSignOrConvertNode() {
  if (isNodeMimeTypeDOCX(props.node) || isNodeMimeTypeXLSX(props.node)) {
    nodeConvertPdfAndSign.doConvertWordToPdfAndSign(props.node);
    return;
  }
  openDeepSignDocument(props.node);
}

watch(
  () => props.node,
  (newValue) => {
    checkCanOpenOnDeepsign(newValue);
  },
  { immediate: true }
);

function onEditNode() {
  const routeData = router.resolve({
    name: 'node-edit',
    params: { node: props.node?.nodeId },
    query: { mode: 'edit' }
  });
  return window.open(routeData.href, '_blank');
}

async function checkCanOpenOnDeepsign(node) {
  canOpenDocumentOnDeepSign.value = await onCanOpenDocumentOnDeepsign(node);
}
</script>

<style scoped lang="scss">
.deepsign-button {
  filter: invert(58%) sepia(92%) saturate(6%) hue-rotate(341deg) brightness(93%)
    contrast(85%);
}

.preview-btn-fab-actions {
  transition: right 200ms ease-in-out;
  z-index: 99;
  position: fixed;
  top: 64px;
  right: 320px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 10px 0 0;

  &.no-drawer {
    right: 0;
  }
}
</style>
