import { computed, ref, watch } from 'vue';
import { constants } from '@/constants';
import { useMessagesStore } from '@/stores/messages/messages';

export function useSnackbar() {
  const messagesStore = useMessagesStore();

  const marginBottom = ref(0);
  const show = ref(false);
  const background = ref(constants.SNACKBAR_MAIN_COLOR);

  const position = computed(
    () => `margin-right: 30px; margin-bottom: ${marginBottom.value}px;`
  );

  watch(
    () => show.value,
    (newValue) => {
      if (newValue) {
        messagesStore.onSnackbarAdded();
        setPosition();
      } else {
        messagesStore.onSnackbarRemoved();
      }
    }
  );

  function setPosition() {
    const pad = 6;
    const heightSnack = 54;
    marginBottom.value =
      pad + heightSnack * (messagesStore.numVisibleSnackbars - 1);
  }

  return {
    show,
    background,
    position
  };
}
