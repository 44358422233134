import type { VueI18n } from 'vue-i18n';
import { format, isToday, isYesterday } from 'date-fns';

export function formatDate(date: Date | string) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

export function formatDateToLocale(dateString: string, locale = 'de-CH') {
  return dateString && dateString !== ''
    ? new Date(dateString).toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    : '';
}

export function formatDateTimeByDefaultFormat(dateString: string) {
  return dateString && dateString !== ''
    ? format(new Date(dateString), 'dd.MM.yyyy, HH:mm:ss')
    : '';
}

export function formatDateTimeWithoutSeconds(dateString: string) {
  return dateString && dateString !== ''
    ? format(new Date(dateString), 'dd.MM.yyyy, HH:mm')
    : '';
}

export function formatDateByDefaultFormat(dateString: string) {
  return dateString && dateString !== ''
    ? format(new Date(dateString), 'dd.MM.yyyy')
    : '';
}

export function formatDateTimeToLocale(dateString: string, locale = 'de-CH') {
  return dateString && dateString !== ''
    ? new Date(dateString).toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    : '';
}

export function humanReadableDate(date: Date, i18n: VueI18n) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const timeInterval = date.getTime() - now.getTime();
  const daysInterval = timeInterval / (1000 * 3600 * 24);

  if (daysInterval < 0) {
    return formatDateToLocale(date.toISOString());
  }
  if (daysInterval <= 1) {
    return i18n.t('date.today');
  }
  if (daysInterval <= 2) {
    return i18n.t('date.tomorrow');
  }
  if (daysInterval <= 3) {
    return i18n.t('date.in_two_days');
  }
  if (daysInterval <= 4) {
    return i18n.t('date.in_three_days');
  }
  return formatDateToLocale(date.toISOString());
}

export function diffDaysBetween2Dates(date: Date) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const msPerDay = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utcNow = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  return Math.floor((utcDate - utcNow) / msPerDay);
}

export function isDateExpired(deadline: Date) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const timeInterval = deadline.getTime() - now.getTime();
  return timeInterval / (1000 * 3600 * 24) < 0;
}

export function isDate(value: Date | string) {
  const date = new Date(value);
  return date.toString() !== 'Invalid Date';
}

export function getDateTimeSince(dateStr: string, i18n: VueI18n) {
  if (typeof dateStr === 'undefined' || dateStr === null) {
    return dateStr;
  }
  // fix ios device
  const t = dateStr.split(/[- : T]/);
  // Apply each element to the Date function
  const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]));
  const dTimeWithoutSeconds = d.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });

  if (isToday(d)) {
    return `${i18n.t('date.today')} ${dTimeWithoutSeconds}`;
  } else if (isYesterday(d)) {
    return `${i18n.t('date.yesterday')} ${dTimeWithoutSeconds}`;
  } else {
    return formatDateToLocale(d);
  }
}

export function timestampToDate(timestamp: string, locale = 'de-CH') {
  const dateObject = new Date(timestamp);
  return dateObject.toLocaleString(locale);
}
