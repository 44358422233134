<template>
  <div class="d-flex flex-column pa-2">
    <div class="flex-grow-0" style="overflow-y: auto; height: 100%">
      <template v-if="showAsIframe">
        <iframe
          v-show="!isIframeLoading"
          class="iframe"
          allow="clipboard-read; clipboard-write"
          ref="iframe"
          title="deep-flow-runtime"
          :src="iframeSrc"
          height="100%"
          width="100%"
          @load="onIFrameLoad"
        ></iframe>
      </template>
      <chat-box
        v-else
        class="text-body-2"
        :chat-id="chatId"
        :model="model"
        :lang="lang ? lang.toLowerCase().substring(0, 2) : undefined"
        :config-url="CONFIG_URL"
        :api-key="API_KEY"
        :debug="String(debug)"
      ></chat-box>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import '@abacus/deepa-chat-box-wc';

const props = defineProps({
  chatId: {
    type: String,
    required: true
  },
  model: {
    type: String,
    default: 'AZURE'
  },
  lang: {
    type: String,
    default: 'de'
  },
  debug: {
    type: Boolean,
    default: false
  },
  showAsIframe: {
    type: Boolean,
    default: false
  }
});

const API_KEY = import.meta.env.VITE_DEEP_OPEN_API_DEFINITION_API_KEY;
const CONFIG_URL = `${
  import.meta.env.VITE_DEEP_OPEN_API_DEFINITION_API_BASE_URL
}/chat/configuration`;

const iframeSrc = ref(
  `https://abacus-research.pages.abaservices.ch/deepa/chat-frontend/?chat-id=${props.chatId}&model=${props.model}&lang=${props.lang}`
);
const isIframeLoading = ref(false);

function onIFrameLoad() {
  isIframeLoading.value = false;
}
</script>

<style scoped lang="scss">
.iframe {
  border: 0;
}

chat-box {
  /* Example properties that can be overridden to make the thing look different */

  //--clr-primary: 256 66%;
  //--bubble-gap: 5rem;
  //--bubble-padding: 5rem;
  //--bubble-border-radius: 99999vmax;
  //--text-bubble: green;
  //--bg-bubble-user: red;
  //--bg-bubble-agent: blue;

  height: 100%;
}
</style>
