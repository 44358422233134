import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';

import type { BoxInfo } from '@/api/types/deepbox/box';
import { deepBoxBoxesInfoAPI } from '@/api/deepbox/boxes/boxes-info';
import { deepBoxDeepBoxesBoxesQueueAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-queue.ts';
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts';
import { DeepBoxError } from '@/models';

export const useDeepBoxBoxInfoStore = defineStore('deepBoxBoxInfo', () => {
  const boxInfo = ref<BoxInfo>();
  const fetchBoxInfoPending = ref(false);

  async function fetchBoxInfo(boxNodeId: string) {
    fetchBoxInfoPending.value = true;
    try {
      const res = await deepBoxBoxesInfoAPI.get(boxNodeId);
      const resData = res.data;
      boxInfo.value = { ...resData };

      return Promise.resolve(res);
    } catch (error) {
      const errorCode = error.response?.status;
      if (errorCode === 400) {
        DeepErrorHandlerService.error(
          new DeepBoxError('error.invalid_or_missing_params')
        );
      }

      await Promise.reject(error);
      return false;
    } finally {
      fetchBoxInfoPending.value = false;
    }
  }

  async function updateBoxInfoQueueCounterByAPI(typeId: string, boxId: string) {
    if (!boxInfo.value) return;
    // this fn should update the queueCount prop inside "boxInfo" in the store: deepBoxBoxInfoStore
    // we use the API: deepBoxDeepBoxesBoxesQueueAPI to make the call faster as "re-fetch" the boxInfo

    const res = await deepBoxDeepBoxesBoxesQueueAPI.get(typeId, boxId, {
      // the limit = 0 is to make the API faster as we only need the "size" from the response
      limit: 0
    });

    boxInfo.value = {
      ...boxInfo.value,
      queueCount: res.data.size
    };
  }

  function increaseBoxInfoQueueCounter(count = 1) {
    if (!boxInfo.value) return;
    boxInfo.value = {
      ...boxInfo.value,
      queueCount: boxInfo.value.queueCount + count
    };
  }

  function decreaseBoxInfoQueueCounter(count = 1) {
    if (!boxInfo.value) return;
    boxInfo.value = {
      ...boxInfo.value,
      queueCount: boxInfo.value.queueCount - count
    };
  }

  return {
    // state
    boxInfo,
    fetchBoxInfoPending,
    // actions
    fetchBoxInfo,

    updateBoxInfoQueueCounterByAPI,
    increaseBoxInfoQueueCounter,
    decreaseBoxInfoQueueCounter
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxBoxInfoStore, import.meta.hot)
  );
}
