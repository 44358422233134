import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import i18n from '@/plugins/i18n.ts';
import { storageApi } from '@/api/interceptors';
import type { PathSegment } from '@/api/types/deepbox/path';
import { constants } from '@/constants';

interface WindowsClientVersion {
  version: string;
  exe: string;
}

export const useGlobalStore = defineStore('global', () => {
  const fetchInitialPending = ref(false);
  const boxDetailsBreadCrumbs = ref<Record<string, unknown>[]>([]);
  const boxDetailsDrawer = ref(false);
  const boxDetailsDrawerScrollId = ref<string | null>(null);
  const mobileSearch = ref(false);
  const showMobileSearchResult = ref(false);
  const searchTerm = ref('');
  const boxSearchTerm = ref('');
  const lastSearchTerm = ref<string | null>(null);
  const isSearchbarExpanded = ref(false);
  const fetchWindowsClientVersionPending = ref(false);
  const windowsClientVersion = ref<WindowsClientVersion>({
    version: '',
    exe: ''
  });

  function setBoxDetailsDrawer(show: boolean) {
    boxDetailsDrawer.value = show;
  }

  function openBoxDetailsDrawer() {
    boxDetailsDrawer.value = true;
  }

  function setBoxDetailsDrawerScrollId(id: string) {
    boxDetailsDrawerScrollId.value = id;
  }

  function setShowMobileSearchResult(show: boolean) {
    showMobileSearchResult.value = show;
  }

  function setBoxDetailsBreadCrumbs(payload: {
    segments: Record<string, unknown>[] | PathSegment[];
    typeId: string;
    boxId: string;
  }) {
    boxDetailsBreadCrumbs.value = [];
    payload.segments.forEach((item) => {
      let text = item.displayName;
      let to = item.nodeId;
      const disabled = false;
      switch (item.name) {
        case constants.SECTION_FILES:
          text = i18n.global.t('box_details.navigation.files');
          to = {
            name: 'organization-types-type-boxes-box-files',
            params: {
              type: payload.typeId,
              box: payload.boxId
            }
          };
          break;
        case constants.SECTION_TRASH:
          text = i18n.global.t('box_details.navigation.trash');
          to = {
            name: 'organization-types-type-boxes-box-trash',
            params: {
              type: payload.typeId,
              box: payload.boxId
            }
          };
          break;
        case constants.SECTION_INBOX:
        case constants.SECTION_QUEUE:
          text = i18n.global.t('box_details.navigation.inbox');
          // Won't be ever used as we cannot go deeper in the inbox
          to = 'inbox';
          break;

        // no default
      }
      boxDetailsBreadCrumbs.value.push({
        exact: true,
        text,
        to,
        disabled,
        ...item
      });
    });
    // Disable last item
    boxDetailsBreadCrumbs.value[
      boxDetailsBreadCrumbs.value.length - 1
    ].disabled = true;
  }

  function setBoxSearchTerm(payload: string) {
    boxSearchTerm.value = payload;
  }

  function setIsSearchbarExpanded(isExpanded: boolean) {
    isSearchbarExpanded.value = isExpanded;
  }

  async function fetchWindowsClientVersion() {
    fetchWindowsClientVersionPending.value = true;
    try {
      const response = await storageApi.get(
        import.meta.env.VITE_DEEPBOX_WINDOWS_CLIENT_PATH
      );
      windowsClientVersion.value = { ...response.data };
    } catch (error) {
      await Promise.reject(error);
    } finally {
      fetchWindowsClientVersionPending.value = false;
    }
  }

  return {
    // state
    fetchInitialPending,
    boxDetailsBreadCrumbs,
    boxDetailsDrawer,
    boxDetailsDrawerScrollId,
    mobileSearch,
    showMobileSearchResult,
    searchTerm,
    boxSearchTerm,
    lastSearchTerm,
    isSearchbarExpanded,
    fetchWindowsClientVersionPending,
    windowsClientVersion,
    // actions
    setBoxDetailsDrawer,
    openBoxDetailsDrawer,
    setBoxDetailsDrawerScrollId,
    setShowMobileSearchResult,
    setBoxDetailsBreadCrumbs,
    setBoxSearchTerm,
    setIsSearchbarExpanded,
    fetchWindowsClientVersion
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
}
