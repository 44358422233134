<template>
  <div v-if="error.deepBoxError === true">
    <v-img v-if="error.img" v-bind="error.img" />
    <div v-if="error.title || error.description">
      <h4 v-if="error.title" class="text-h4">
        {{ t(error.title) }}
      </h4>
      <p
        v-if="error.description"
        class="mt-3"
        v-html="getSanitizedHtml(t(error.description))"
      ></p>
    </div>
    <div v-else>
      <h4 v-if="error.message" class="text-h4 font-weight-medium">
        {{ t(error.message) }}
      </h4>
    </div>
    <div v-if="!error.hideStatusCode && error.statusCode" class="mt-6">
      {{ t('error.code') + ': ' + error.statusCode }}
    </div>
    <div class="d-flex flex-row justify-center mt-6">
      <VBtnPrimary
        v-if="error.btn"
        v-bind="error.btn"
        @click="error.btn.action ? error.btn.action() : goHome()"
      >
        <span v-if="error.btn.title">{{ error.btn.title }}</span>
        <span v-else>
          {{ t('error.home') }}
        </span>
      </VBtnPrimary>
      <v-btn v-else color="primary" @click="goHome">
        {{ t('error.home') }}
      </v-btn>
    </div>
  </div>
  <div v-else-if="error.statusCode === 404">
    <h1>
      {{ t('error.page_not_found') }}
    </h1>
    <div v-if="!error.hideStatusCode">
      {{ t('error.code') + ': ' + error.statusCode }}
    </div>
  </div>
  <div v-else-if="error.name === 'ExpiredAuthSessionError'">
    <h1>
      {{ t('error.logged_out') }}
    </h1>

    <div class="d-flex flex-row justify-center mt-6">
      <v-btn color="primary" @click="login()">
        {{ t('error.login') }}
      </v-btn>
    </div>
  </div>
  <div v-else>
    <h1>
      {{ t('error.error_occurred') }}
    </h1>
    <div v-if="!error.hideStatusCode">
      {{ t('error.code') + ': ' + error.statusCode }}
    </div>
    <div class="my-4">
      {{ error.message }}
    </div>
    <div class="d-flex flex-row justify-center mt-6">
      <v-btn color="primary" @click="goHome">
        {{ t('error.home') }}
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useKeycloak } from '@/composables/auth/use-keycloak';
import { getSanitizedHtml } from '@/utils/helpers/html-sanitize.ts';
import { DeepBoxError } from '@/models';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  error: {
    type: [Object, Error, DeepBoxError],
    default: () => ({})
  },
  organization: {
    type: Object,
    default: () => ({})
  }
});

const { t } = useI18n();
const { login } = useKeycloak();

function goHome() {
  let href = '/';
  if (props.organization?.group_id) {
    href += props.organization.group_id;
  }
  /*
   *  Currently router.push(/) is not working in the most of the cases
   * is causing an issue where the previous route is re-mounted again
   * window.location.href works better, but also not in all cases
   * window.location.href also causes an "page-reload" which is not the best solution
   * */
  // router.push(href);
  window.location.href = href;
}
</script>
