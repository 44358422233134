import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { constants } from '@/constants';
import type { DeepBox } from '@/api/types/deepbox/deep-box';
import type { Box, BoxInfo, BoxEntry } from '@/api/types/deepbox/box';
import type { Node } from '@/api/types/deepbox/node';
import type { RouteLocation } from 'vue-router';
import type { AnalyzePostQueryParams } from '@/api/types/deepbox/analyze';
import { deepBoxNodesAnalyzeAPI } from '@/api/deepbox/nodes/nodes-analyze';
import { deepBoxDeepBoxesAPI } from '@/api/deepbox/deepboxes/deepboxes';
import { deepBoxDeepBoxesBoxesTrashAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-trash';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes';

export const TAG = '[deepbox:core:store]';

export interface LastSelectedNode {
  item?: Node;
  index?: number;
}

export const useDeepBoxCoreStore = defineStore('deepBoxCore', () => {
  const types = ref<DeepBox[]>([]);
  const type = ref<DeepBox | undefined>();
  const selectedBox = ref<Box | BoxInfo | BoxEntry>();
  const lastSelectedNode = ref<LastSelectedNode>();
  const deleteTrashPending = ref(false);
  const fetchTypePending = ref(false);
  const selectedSearchLocation = ref(null);
  const mobileSearchField = ref('');
  const drawerDisplayTree = ref(false);

  async function fetchType(deepBoxNodeId: string) {
    fetchTypePending.value = true;
    try {
      const res = await deepBoxDeepBoxesAPI.getById(deepBoxNodeId);
      type.value = { ...res.data };
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      fetchTypePending.value = false;
    }
  }

  async function deleteTrash(route: RouteLocation) {
    deleteTrashPending.value = true;
    try {
      const res = await deepBoxDeepBoxesBoxesTrashAPI.deleteById(
        route.params.type,
        route.params.box
      );
      const deepBoxDeepBoxesBoxesNodesStore =
        useDeepBoxDeepBoxesBoxesNodesStore();
      if (deepBoxDeepBoxesBoxesNodesStore.data) {
        deepBoxDeepBoxesBoxesNodesStore.data = {
          ...deepBoxDeepBoxesBoxesNodesStore.data,
          size: 0,
          nodes: []
        };
      }
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      deleteTrashPending.value = true;
    }
  }

  async function analyzeNode(payload: Node) {
    // prevent to start the analysis when the node do not have the policy to do it
    if (!payload?.policy?.canAnalyze) return;
    // call the analysis action
    const node = {
      ...payload,
      analysisStatus: constants.ANALYSIS_STATUS_RUNNING
    };

    // stores
    const deepBoxDeepBoxesBoxesNodesStore =
      useDeepBoxDeepBoxesBoxesNodesStore();

    if (deepBoxDeepBoxesBoxesNodesStore.data) {
      const nodes = [...deepBoxDeepBoxesBoxesNodesStore.data.nodes];
      const nodeIndex = nodes?.findIndex((n) => n.nodeId === node.nodeId);
      if (nodeIndex !== -1) {
        const n = nodes[nodeIndex];
        nodes[nodeIndex] = { ...n, ...node };
      }
      deepBoxDeepBoxesBoxesNodesStore.data.nodes = nodes;
    }

    const params: AnalyzePostQueryParams = { waitForResult: 0 };
    await deepBoxNodesAnalyzeAPI.post(payload.nodeId, params);
  }

  return {
    // states
    types,
    type,
    selectedBox,
    lastSelectedNode,
    deleteTrashPending,
    fetchTypePending,
    selectedSearchLocation,
    mobileSearchField,
    drawerDisplayTree,
    // actions
    fetchType,
    deleteTrash,
    analyzeNode
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeepBoxCoreStore, import.meta.hot));
}
