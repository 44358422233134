import { defineStore, acceptHMRUpdate } from 'pinia';

import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { deepAdminUsersCompanyAccountingAPI } from '@/api/deepadmin/users/users-company-accounting';
import type { CompanyAccounting } from '@/api/types/deepadmin/users/users-company-accounting';
import { computed, ref } from 'vue';

export const useDeepAdminUsersCompanyAccountingStore = defineStore(
  'deepAdminUsersCompanyAccounting',
  () => {
    const fetchCompanyAccountingPending = ref(false);
    const companyAccounting = ref<CompanyAccounting>({
      has_open_invoices: false,
      has_resolvable_invoices: false,
      has_overused_box_users: false,
      has_overused_subscriptions: false
    } as CompanyAccounting);

    const showCompanyAccountingAlert = computed(() => {
      const hasResolvableInvoices =
        companyAccounting.value?.has_resolvable_invoices || false;
      const hasOverusedSubscriptions =
        companyAccounting.value?.has_overused_subscriptions || false;
      return hasResolvableInvoices || hasOverusedSubscriptions;
    });

    async function fetchCompanyAccounting(organizationId: string) {
      fetchCompanyAccountingPending.value = true;
      try {
        let orgId = organizationId;
        const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
        if (!organizationId && deepAdminUsersMeStore.selectedOrganizationId) {
          orgId = deepAdminUsersMeStore.selectedOrganizationId;
        }
        const res = await deepAdminUsersCompanyAccountingAPI.get(orgId);
        const resData = res?.data;
        companyAccounting.value = { ...resData };
        return Promise.resolve(res);
      } catch (error) {
        companyAccounting.value = {
          has_open_invoices: false,
          has_resolvable_invoices: false,
          has_overused_box_users: false,
          has_overused_subscriptions: false
        };
        return Promise.reject(error);
      } finally {
        fetchCompanyAccountingPending.value = false;
      }
    }

    return {
      // state
      fetchCompanyAccountingPending,
      companyAccounting,
      // actions
      showCompanyAccountingAlert,
      fetchCompanyAccounting
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepAdminUsersCompanyAccountingStore, import.meta.hot)
  );
}
