import { ref } from 'vue';

// stores
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts';
import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts';
import { useDeepBoxDeepBoxesBoxesFilesTreeStore } from '@/stores/deepbox/deepboxes/boxes/files-tree.ts';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes';
import { useDeepBoxSearchStore } from '@/stores/deepbox/search.ts';

// composables
import { type RouteLocation } from 'vue-router';
import { useFilesTree } from '@/composables/use-files-tree.ts';

// apis
import { deepBoxApi } from '@/api/interceptors';
import { deepBoxNodesAPI } from '@/api/deepbox/nodes/nodes.ts';
import { deepBoxNodesInfoAPI } from '@/api/deepbox/nodes/nodes-info.ts';

// types & constants
import type {
  ContentData,
  ContentDataParams
} from '@/api/types/deepbox/globals.ts';
import { constants } from '@/constants';
import type { FileTree } from '@/types/file-tree.ts';
import type { Sections } from '@/types/sections.ts';

// utilities
import helpers from '@/utils/helpers.ts';
import type {
  Node,
  NodeUpdate,
  PatchNodePayload
} from '@/api/types/deepbox/node.ts';
import orderBy from 'lodash/orderBy';
import { sortStringToObject } from '@/utils/deep';
import type { RouteTo } from '@/types/route-to.ts';

export function useNodeApi() {
  // stores
  const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore();
  const deepBoxCoreStore = useDeepBoxCoreStore();
  const deepBoxSearchStore = useDeepBoxSearchStore();

  const addNodePending = ref(false);

  async function addNode(payload: {
    name?: string;
    i18n?: Record<string, unknown>;
    route?: RouteLocation;
    refresh?: boolean;
  }) {
    addNodePending.value = true;
    let section: Sections;
    try {
      const typeId = payload?.route?.params?.type as string;
      const boxId = payload?.route?.params?.box as string;
      const nodeId = payload?.route?.params?.node as string;

      let url = 'deepBoxes/';
      let data: Record<string, unknown>[] = [];
      switch (payload.route?.name) {
        case 'organization-types-type-boxes-box-files-node':
          url += `${typeId}/boxes/${boxId}/files/${nodeId}`;
          section = 'fileNodes';
          break;
        case 'organization-types-type-boxes-box-files':
          url += `${typeId}/boxes/${boxId}/files`;
          section = 'files';
          break;

        // no default
      }

      if (payload.name) {
        url += '/path';
        data = [{ name: payload.name, i18n: payload.i18n }];
      }

      const res = await deepBoxApi.post(url, data);
      const { node } = res.data[0];

      const setStateOrderParams = (
        contentData: ContentData,
        contentDataParams: ContentDataParams
      ) => {
        if (!contentData) return;
        // Sort nodes
        const order = sortStringToObject(contentDataParams.order);

        if (order && order.sortBy && order.sortBy.length > 0) {
          const desc = order.sortDesc[0] === true ? 'desc' : 'asc';
          const sorter = helpers.getSorter(order);
          contentData.nodes = orderBy(contentData.nodes, sorter, desc);
          contentData.nodes = orderBy(contentData.nodes, ['type'], 'desc');
        }
      };

      // SET NODE
      if (deepBoxDeepBoxesBoxesNodesStore.data) {
        deepBoxDeepBoxesBoxesNodesStore.data.nodes.push(node);
        deepBoxDeepBoxesBoxesNodesStore.data.size += 1;
        setStateOrderParams(
          deepBoxDeepBoxesBoxesNodesStore.data,
          deepBoxDeepBoxesBoxesNodesStore.params
        );
      }

      // Select node in nodeDataTable
      deepBoxDeepBoxesBoxesNodesStore.selectedNodeIds = [node.nodeId];

      const { refreshFilesTreeBySection } = useFilesTree();
      await refreshFilesTreeBySection(typeId, boxId, section, nodeId);

      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      addNodePending.value = false;
    }
  }

  const saveNodePending = ref(false);

  async function patchNode(payload: PatchNodePayload) {
    saveNodePending.value = true;
    try {
      const body: NodeUpdate = { name: payload.name };
      if (payload?.policy?.canI18n || payload?.policy?.canI18n) {
        body.i18n = payload.i18n;
      }
      const res = await deepBoxNodesAPI.patchById(payload.nodeId, body);

      // https://jira.abacus.ch/browse/ATK-19087 we need to get the NODE after patch it
      // because the API do not return the updated NODE
      const resNodeGet = await deepBoxNodesInfoAPI.get(payload.nodeId);
      deepBoxDeepBoxesBoxesNodesStore.updateNodeState(resNodeGet.data.node);

      if (deepBoxCoreStore.drawerDisplayTree) {
        const deepBoxDeepBoxesBoxesFilesTreeStore =
          useDeepBoxDeepBoxesBoxesFilesTreeStore();
        const nodeIndex =
          deepBoxDeepBoxesBoxesFilesTreeStore.filesTree.findIndex(
            (node) => node.nodeId === payload.nodeId
          );
        if (nodeIndex < 0) {
          // TODO: check if should maybe be reject
          return Promise.resolve(res);
        }
        const nodes: FileTree[] = [
          ...deepBoxDeepBoxesBoxesFilesTreeStore.filesTree
        ];
        nodes[nodeIndex] = { ...payload };
        deepBoxDeepBoxesBoxesFilesTreeStore.filesTree = nodes;
      }
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      saveNodePending.value = false;
    }
  }

  const deleteNodePending = ref(false);

  function deleteNode(payload: { section: string; nodeId: string }) {
    deleteNodePending.value = false;
    if (!payload) {
      return;
    }
    const { section, nodeId } = payload;

    // delete the node from state data
    if (
      deepBoxDeepBoxesBoxesNodesStore.data &&
      deepBoxDeepBoxesBoxesNodesStore.data.nodes
    ) {
      deepBoxDeepBoxesBoxesNodesStore.removeNodeState(nodeId);
    }

    // check if the delete node is within the searchResults
    if (
      deepBoxSearchStore.searchResults &&
      typeof deepBoxSearchStore.searchResults.items !== 'undefined'
    ) {
      const dataSetSearch = { ...deepBoxSearchStore.searchResults };
      const index = dataSetSearch.items.findIndex(
        (item) => item.nodeId === nodeId
      );
      if (index >= 0) {
        // if the node was already in trash, remove it from the list
        if (section === constants.SECTION_TRASH) {
          dataSetSearch.items.splice(index, 1);
          dataSetSearch.size -= 1;
        } else {
          // else, change its location to trash
          const trashNode = deepBoxDeepBoxesBoxesNodesStore.getCurrentRootNode;
          if (trashNode) {
            dataSetSearch.items[index].parentPath.segments = [trashNode];
          }
        }

        deepBoxSearchStore.searchResults = dataSetSearch;
      }
    }
  }

  const addFilePending = ref(false);

  function addFileFulfilled(payload: {
    section: string | null;
    node: Node;
    route?: RouteTo;
  }) {
    console.log('addFileFulfilled', payload);
    const section = helpers.getSectionFromRoute(payload.route);
    if (section && section !== payload.section) {
      // update inbox counter if add node to inbox
      if (
        [constants.SECTION_QUEUE, constants.SECTION_INBOX].includes(
          payload.section
        )
      ) {
        const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore();
        deepBoxBoxInfoStore.increaseBoxInfoQueueCounter();
      }
      return;
    }

    if (deepBoxDeepBoxesBoxesNodesStore.data) {
      // extract the current order
      const order = sortStringToObject(
        deepBoxDeepBoxesBoxesNodesStore.params?.order
      );
      console.log('order', order);

      // add the node at the end of the dataset
      deepBoxDeepBoxesBoxesNodesStore.data.nodes.push(payload.node);

      // then sort it
      if (order && order?.sortBy?.length > 0) {
        const desc = order.sortDesc[0] === true ? 'desc' : 'asc';
        const sorter = helpers.getSorter(order);
        // sort!
        deepBoxDeepBoxesBoxesNodesStore.data.nodes = orderBy(
          deepBoxDeepBoxesBoxesNodesStore.data.nodes,
          sorter,
          desc
        );
      }

      // Sort folders to be always first
      deepBoxDeepBoxesBoxesNodesStore.data.nodes = helpers.getFoldersFirst(
        deepBoxDeepBoxesBoxesNodesStore.data.nodes
      );

      // update the size
      deepBoxDeepBoxesBoxesNodesStore.data.size += 1;

      // assign the modified dataset to the state to be sure that data is updated
      deepBoxDeepBoxesBoxesNodesStore.data = {
        ...deepBoxDeepBoxesBoxesNodesStore.data
      };
    }

    addFilePending.value = false;
  }

  return {
    addNode,
    addNodePending,
    patchNode,
    saveNodePending,
    deleteNode,
    deleteNodePending,
    addFileFulfilled,
    addFilePending
  };
}
