<template>
  <!-- BOX ITEM -->
  <div
    class="deepbox-box-item d-flex flex-row align-center pl-0 text-body-2"
    :class="[{ 'deepbox-box-item-shared': isShared }]"
    @click="emit('click:box-item', $event)"
  >
    <slot name="item">
      <slot name="item-link">
        <router-link
          class="d-inline-flex align-center box-item"
          :class="{
            'box-link': !isBoxSelectionMenu,
            'box-selection': isBoxSelectionMenu
          }"
          :to="boxItemRouteTo"
          style="cursor: pointer"
          :data-test-id="boxTestId"
        >
          <BoxItem
            :is-shared="isShared"
            :is-inbox="isInbox"
            :is-recent="isRecent"
            :is-favourite="isFavourite"
            :is-deadline="isDeadline"
            :box="box"
            :box-avatar-size="boxAvatarSize"
            :box-avatar-initials-size="boxAvatarInitialsSize"
            :is-box-selection-menu="isBoxSelectionMenu"
            :show-box-name-subtitle="showBoxNameSubtitle"
          />
        </router-link>
      </slot>
      <!-- /BOX NAME / BOX ORGANIZATION -->

      <slot name="item-action">
        <!-- INBOX COUNTER-->
        <router-link
          v-if="!isBoxSelectionMenu && box.queueCount > 0 && !isDeadline"
          class="box-link inbox-count text-truncate"
          :class="{
            'mr-6': isInbox
          }"
          :to="{
            name: 'organization-types-type-boxes-box-inbox',
            params: {
              organization: deepAdminUsersMeStore.selectedOrganizationId,
              type: box.deepBoxNodeId,
              box: box.boxNodeId
            }
          }"
        >
          {{ box.queueCount }}
        </router-link>
        <!-- /INBOX COUNTER-->

        <!-- DEADLINE DATE-->
        <div
          v-if="isDeadline"
          class="d-flex flex-row flex-fill justify-end text-caption"
          :class="{
            'text-error': isDateExpired(new Date(box.nextDate))
          }"
        >
          {{
            humanReadableDate(new Date(box.nextDate), i18nInstanceGlobal.global)
          }}
        </div>
        <!-- /DEADLINE DATE-->

        <!-- FAVOURITE ICON -->

        <div
          class="favourite-icon-inactive text-right"
          :class="box.queueCount > 0 ? '' : 'ml-auto'"
        >
          <BoxAdminMenu
            v-if="canManageBoxes"
            :box="box"
            @box:updated="emit('box:updated', $event)"
            @box:deleted="emit('box:deleted', $event)"
          />
          <div
            v-else-if="showFavorite"
            class="favourite-icon-inactive text-right"
          >
            <v-btn
              v-if="!isFav(box)"
              color="transparent"
              :icon="true"
              size="small"
              @click.stop="addToFav(box)"
            >
              <v-icon class="add-to-fav-btn" color="whitesmoke">
                far fa-star
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              color="transparent"
              :icon="true"
              size="small"
              @click.stop="removeFromFav(box)"
            >
              <v-icon class="remove-from-fav-btn" color="primary">
                fas fa-star
              </v-icon>
            </v-btn>
          </div>
        </div>
      </slot>
    </slot>
    <!-- /FAVOURITE ICON -->
  </div>
  <!-- /BOX ITEM -->
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import i18nInstanceGlobal from '@/plugins/i18n.ts';

// components
import BoxAdminMenu from '@/components/box/box-admin/BoxAdminMenu.vue';
import BoxItem from '@/components/box/BoxItem.vue';

// stores
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { useDeepBoxDeepBoxesBoxesFavoritesStore } from '@/stores/deepbox/deepboxes/boxes/favorites';

// composables
import { useRoute } from 'vue-router';

// types & constants
import type { BoxEntry } from '@/api/types/deepbox/box';

// utilities
import { humanReadableDate, isDateExpired } from '@/utils/helpers/date.ts';

const props = defineProps({
  isShared: {
    type: Boolean,
    default: false
  },
  isInbox: {
    type: Boolean,
    default: false
  },
  isRecent: {
    type: Boolean,
    default: false
  },
  isFavourite: {
    type: Boolean,
    default: false
  },
  isDeadline: {
    type: Boolean,
    default: false
  },
  box: {
    type: Object as PropType<BoxEntry>,
    default: () => ({})
  },
  boxAvatarSize: {
    type: [String, Number],
    default: undefined
  },
  boxAvatarInitialsSize: {
    type: String,
    default: undefined
  },
  showFavorite: {
    type: Boolean,
    default: false
  },
  isBoxSelectionMenu: {
    type: Boolean,
    default: false
  },
  showBoxNameSubtitle: {
    type: Boolean,
    default: false
  },
  canManageBoxes: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([
  'click:box-item',
  'box:updated',
  'box:added',
  'box:deleted'
]);

const route = useRoute();

const deepBoxDeepBoxesBoxesFavoritesStore =
  useDeepBoxDeepBoxesBoxesFavoritesStore();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();

function isFav(box: BoxEntry) {
  if (deepBoxDeepBoxesBoxesFavoritesStore.favorites.length > 0) {
    return deepBoxDeepBoxesBoxesFavoritesStore.favorites.find(
      (fav) => fav.boxNodeId === box.boxNodeId
    );
  }
  return false;
}

function addToFav(box: BoxEntry) {
  deepBoxDeepBoxesBoxesFavoritesStore.addToFavorites(box);
}

function removeFromFav(box: BoxEntry) {
  deepBoxDeepBoxesBoxesFavoritesStore.removeFromFavorites(box);
}

const boxItemRouteTo = computed(() => {
  if (props.isBoxSelectionMenu) return '';

  if (props.isInbox) {
    return {
      name: 'organization-types-type-boxes-box-inbox',
      params: {
        organization: deepAdminUsersMeStore.selectedOrganizationId,
        type: props.box.deepBoxNodeId,
        box: props.box.boxNodeId
      }
    };
  } else if (props.isDeadline) {
    return {
      name: 'organization-types-type-boxes-box-search',
      params: {
        organization: route.params.organization,
        type: props.box.deepBoxNodeId,
        box: props.box.boxNodeId
      },
      query: { tags: 'deadline' }
    };
  }

  return {
    name: 'organization-types-type-boxes-box-files',
    params: {
      organization: deepAdminUsersMeStore.selectedOrganizationId,
      type: props.box.deepBoxNodeId,
      box: props.box.boxNodeId
    }
  };
});

const boxTestId = computed(() => {
  if (props.isInbox) {
    return `inbox-item-${props.box.boxName}`;
  } else if (props.isDeadline) {
    return `deadline-item-${props.box.boxName}`;
  }
  return `box-item-${props.box.boxName}`;
});
</script>

<style scoped lang="scss">
.deepbox-box-item {
  height: 40px;
  border-radius: 5px !important;
  width: 100%;
  text-align: left;

  &:hover {
    .favourite-icon-inactive {
      opacity: 1;
    }
  }
}

.deepbox-box-item-shared .box-link:first-child:hover {
  text-decoration: none;
}

.box-item {
  text-decoration: none !important;
  color: rgb(var(--v-theme-black));
}

.inbox-count {
  margin-left: auto;
  margin-right: 4px;
}

.box-link {
  text-decoration: none !important;
  color: rgb(var(--v-theme-black));

  & :deep(.deepbox-box-name) {
    margin-left: 10px;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.favourite-icon-inactive {
  opacity: 0;
}
</style>
