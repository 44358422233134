import { ref } from 'vue';

/**
 * Watch an external resource value for changes
 * @param {() => Promise<any>} fetchFn - promise function that returns the value for comparison
 * @param {number} intervalDelay - interval delay of calling the fetchFn
 * @param {(newValue: any, oldValue: any) => boolean} [hasChangedFn] - optional function to compare the values
 */
export function useWatchExternalResource(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFn: () => Promise<any>,
  intervalDelay = 60000,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasChangedFn?: (newValue: any, oldValue: any) => boolean
) {
  const hasChanged = ref(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let valueOnStart: any;
  let intervalId: null | ReturnType<typeof setTimeout> = null;

  fetchFn()
    .then((value) => {
      valueOnStart = value;
      return Promise.resolve();
    })
    .then(() => {
      if (valueOnStart) {
        startInterval();
      }
    });

  function startInterval() {
    intervalId = setInterval(async () => {
      fetchFn()
        .then((value) => {
          if (typeof hasChangedFn === 'function') {
            if (hasChangedFn(valueOnStart, value)) {
              hasChanged.value = true;
            }
          } else if (value && value !== valueOnStart) {
            hasChanged.value = true;
          }
        })
        .catch(() => {
          stop();
        });
    }, intervalDelay);
  }

  function stop() {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
  }

  return {
    stop,
    hasChanged
  };
}
