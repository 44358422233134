import type { Node } from '@/api/types/deepbox/node';
import type { PathSegment } from '@/api/types/deepbox/path';
import type { SearchItem } from '@/api/types/deepbox/search';
import { constants } from '@/constants';

export function useNodeMimeType() {
  const isNodeMimeTypeInbox = (node: Node | PathSegment | SearchItem) =>
    node?.mimeType === constants.MIME_TYPE_INBOX || false;

  const isNodeMimeTypeTrash = (node: Node | PathSegment | SearchItem) =>
    node?.mimeType === constants.MIME_TYPE_TRASH || false;

  const isNodeMimeTypeGeneric = (node: Node | PathSegment | SearchItem) =>
    node?.mimeType === constants.MIME_TYPE_GENERIC || false;

  const isNodeMimeTypeFolder = (node: Node | PathSegment | SearchItem) =>
    node?.mimeType === constants.MIME_TYPE_FOLDER || false;

  return {
    isNodeMimeTypeInbox,
    isNodeMimeTypeTrash,
    isNodeMimeTypeGeneric,
    isNodeMimeTypeFolder
  };
}
