import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default [
  {
    path: '/upload',
    component: LayoutDefault,
    name: 'upload',
    redirect: 'upload-id',
    children: [
      {
        path: '/upload/:id?',
        component: () => import('@/pages/upload/UploadIndex.vue'),
        name: 'upload-id',
        meta: {
          auth: false
        }
      }
    ]
  }
];
