<template>
  <v-chip
    v-bind="$attrs"
    pill
    label
    :close-icon="closeIcon"
    class="deep-tag"
    :class="{ 'can-hover': canHoverMediaQuery }"
    color="grey"
    data-test-id="tag-chip"
  >
    <slot name="content">
      <slot name="icon">
        <v-icon size="x-small" :color="color" start>fas fa-circle</v-icon>
      </slot>
      <slot name="text">
        <span class="tag-text">
          {{ text }}
        </span>
      </slot>
    </slot>
  </v-chip>
</template>

<script lang="ts" setup>
import { useHover } from '@/composables/use-hover.ts';

defineProps({
  color: {
    type: String,
    default: null
  },
  text: {
    type: String,
    default: null
  },
  closeIcon: {
    type: String,
    default: 'fas fa-xmark'
  }
});

const { canHoverMediaQuery } = useHover();
</script>

<style lang="scss" scoped>
.deep-tag {
  border-radius: 8px !important;

  :deep(.tag-text) {
    text-align: right;
    pointer-events: all;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    font-kerning: normal;
    vertical-align: baseline;
  }

  &.can-hover {
    :deep(.v-chip__close) {
      opacity: 0;
    }

    :deep(.v-chip__close:hover) {
      opacity: 1;
    }
  }

  :deep(.v-icon.v-icon--start) {
    display: inline-block;
  }

  :deep(.v-icon.v-icon--start:before) {
    border: 0.01em rgb(176, 171, 171) solid;
    border-radius: 12px;
  }
}
</style>
