import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutFilePreview from '@/layouts/LayoutFilePreview.vue';

export default [
  {
    path: '/links',
    component: LayoutDefault,
    name: 'link',
    meta: {},
    children: [
      {
        path: '/links/:id',
        component: () => import('@/pages/link/LinkIndex.vue'),
        name: 'links-id',
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    path: '/links',
    component: LayoutFilePreview,
    name: 'link-preview',
    meta: {},
    children: [
      {
        path: '/links/:id?/preview',
        component: () => import('@/pages/link/LinkPreview.vue'),
        name: 'links-id-preview',
        meta: {
          auth: false
        }
      }
    ]
  }
];
