<template>
  <v-tooltip
    v-bind="$attrs"
    color="#313131"
    content-class="py-0"
    transition="fade"
    close-delay="100"
    open-delay="300"
  >
    <!--
      Dynamically inherit slots from parent
      & make all slots from component reusable from parent
    -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
    <span class="text-caption">{{ text }}</span>
  </v-tooltip>
</template>

<script lang="ts" setup>
defineProps({
  text: {
    type: String,
    default: null
  }
});
</script>
