import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import helpers from '@/utils/helpers.ts';

export function useOrgBetaFeatures() {
  const { getOrganizationById } = useDeepAdminUsersMeStore();

  function isAbaNinjaBoxEnabled(orgId: string) {
    const org = getOrganizationById(orgId);
    return !helpers.isPersonalStructure(org);
  }

  return {
    isAbaNinjaBoxEnabled
  };
}
