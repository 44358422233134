<template>
  <CoreTagBase v-if="tag" v-bind="$attrs" :class="computedClass">
    <template #content>
      <template v-if="tag.key === 'deadline' && isDate(tag.value)">
        <v-icon
          v-if="tag.value"
          class="mr-2"
          :color="`${isDateExpired(new Date(tag.value)) ? 'white' : '#71777B'}`"
          size="x-small"
        >
          far fa-calendar-clock
        </v-icon>
        <span
          v-if="tag.value"
          :style="`${isDateExpired(new Date(tag.value)) ? 'color: white' : ''}`"
          class="tag-text"
        >
          {{ getDeadline(tag.value) }}
        </span>
      </template>
      <template v-else>
        <v-icon :color="tag.colorBg" start size="x-small">
          fas fa-circle
        </v-icon>
        <span class="tag-text">
          {{ tag.displayName }}
        </span>
      </template>
    </template>
  </CoreTagBase>
</template>

<script lang="ts" setup>
import CoreTagBase from '@/components/core/CoreTagBase.vue';
import i18n from '@/plugins/i18n.ts';
import { computed, type PropType } from 'vue';
import type { Tag } from '@/api/types/deepbox/tag';
import {
  humanReadableDate,
  isDate,
  isDateExpired
} from '@/utils/helpers/date.ts';

const props = defineProps({
  tag: {
    type: Object as PropType<Tag>,
    default: () => ({})
  },
  class: {
    type: [String, Object],
    default: undefined
  }
});

const computedClass = computed(() => {
  const cls = { 'bg-error': isDateExpired(new Date(props.tag.value)) };
  if (!props.class) {
    return { 'ma-2': true, ...cls };
  }
  if (typeof props.class === 'object') {
    return { ...cls, ...props.class };
  }
  return { ...cls, [props.class]: true };
});

function getDeadline(value: string) {
  if (isDate(value)) {
    return humanReadableDate(new Date(value), i18n.global);
  }
  return value;
}
</script>
