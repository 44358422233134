<template>
  <div
    class="initials rounded-square-border d-flex flex-row align-center"
    :class="`initials-${size} rounded-square-border-${size}`"
  >
    <slot name="content">
      <h4>
        {{ getInitials(name) }}
      </h4>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { getInitials } from '@/utils/deep/';
import type { PropType } from 'vue';

defineProps({
  name: {
    type: String,
    default: null
  },
  size: {
    type: String as PropType<'md' | 'sm'>,
    default: 'md',
    validator(value: string) {
      // The value must match one of these strings
      return ['sm', 'md'].includes(value);
    }
  }
});
</script>

<style lang="scss" scoped>
.initials {
  color: rgb(var(--v-theme-black));
  text-decoration: none !important;
}

.initials-sm {
  font-size: 9px;
  height: 22px;
  width: 22px;
}

.initials-md {
  font-size: 11px;
  height: 28px;
  width: 28px;
}

.rounded-square-border {
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
}

.rounded-square-border-sm {
  border: 2.5px solid #5d4ac6;
}

.rounded-square-border-md {
  border: 3px solid #5d4ac6;
}
</style>
