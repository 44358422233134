<template>
  <v-menu v-bind="$attrs">
    <!--
      Dynamically inherit slots from parent
      & make all slots from component reusable from parent
    -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
    <v-list :density="density" :color="color" :bg-color="bgColor">
      <template v-if="items.length > 0">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          v-bind="item"
          slim
          :title="item[titleRef]"
          @click="emit('click:item', item)"
        >
          <template v-if="item.icon" #prepend>
            <v-icon class="mr-2" :color="item[iconColorRef]"
              >{{ item[iconRef] }}
            </v-icon>
          </template>

          <template v-if="item.action" #append>
            <v-icon :color="item.action[iconColorRef]">
              {{ item.action[iconRef] }}
            </v-icon>
          </template>
        </v-list-item>
      </template>
      <v-list-item v-else title="-" />
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
defineProps({
  items: {
    type: Array,
    default: () => []
  },
  titleRef: {
    type: String,
    default: 'title'
  },
  iconRef: {
    type: String,
    default: 'icon'
  },
  iconColorRef: {
    type: String,
    default: 'iconColor'
  },
  density: {
    type: String,
    default: 'default'
  },
  color: {
    type: String,
    default: undefined
  },
  bgColor: {
    type: String,
    default: undefined
  }
});

const emit = defineEmits(['click:item']);
</script>
<style lang="scss" scoped>
:deep(.v-list-item) .v-icon {
  opacity: unset;
}
</style>
