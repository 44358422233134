import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import type { Pagination } from '@/api/types/deepbox/pagination';
import type {
  ListTags,
  Tag,
  TagsGetQueryParams
} from '@/api/types/deepbox/tag';
import { deepBoxDeepBoxesBoxesTagsAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-tags';
import { deepBoxNodesTagsAPI } from '@/api/deepbox/nodes/nodes-tags';
import { useNodeTags } from '@/composables/use-node-tags';
import type { NodeTagsUpdate } from '@/api/types/deepbox/node';
import { deepBoxNodesTagsUpdateAPI } from '@/api/deepbox/nodes/nodes-tags-update';

// helper to get formatted tag for the API
const formattedTag = (tag: Tag): string => {
  let tagFormatted = tag.key;
  if (tag.value) {
    tagFormatted += `:${tag.value}`;
  }
  return tagFormatted;
};

export const useDeepBoxTagsStore = defineStore('deepBoxTags', () => {
  const assignTagPending = ref(false);
  const fetchTagsPending = ref(false);
  const fetchTagsByKeyPending = ref(false);
  const setNodeTagsPending = ref(false);
  const tags = ref<ListTags>({
    items: [],
    pagination: {} as Pagination,
    size: 0
  });
  const tag = ref<string>('');
  const tempTags = ref<ListTags>({} as ListTags);

  async function fetchTags(payload: {
    typeId: string;
    boxId: string;
    params: TagsGetQueryParams;
  }) {
    fetchTagsPending.value = true;
    try {
      let { limit, offset } = payload.params;
      if (!limit) {
        limit = 20;
      }
      if (offset === undefined) {
        offset = tags.value.items.length;
      }
      const params = {
        ...payload.params,
        limit,
        offset
      };

      const res = await deepBoxDeepBoxesBoxesTagsAPI.get(
        payload.typeId,
        payload.boxId,
        params
      );

      const resData = res?.data;
      if (resData.pagination.offset === 0) {
        tags.value.items = [...resData.tag];
      } else {
        tags.value.items = [...tags.value.items, ...resData.tag];
      }
      tags.value.pagination = { ...resData.pagination };
      tags.value.size = resData.size;

      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      fetchTagsPending.value = false;
    }
  }

  async function fetchTagsByKey(payload: {
    typeId: string;
    boxId: string;
    params: TagsGetQueryParams;
  }) {
    fetchTagsByKeyPending.value = true;
    try {
      const res = await deepBoxDeepBoxesBoxesTagsAPI.get(
        payload.typeId,
        payload.boxId,
        payload.params
      );
      const resData = res?.data;
      tempTags.value.items = [...resData.tag];
      tempTags.value.pagination = { ...resData.pagination };
      tempTags.value.size = resData.size;
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      fetchTagsByKeyPending.value = false;
    }
  }

  async function assignTag(payload: {
    nodeId: string;
    tags: string[];
    fullTags: Tag[];
  }) {
    assignTagPending.value = true;
    try {
      const res = await deepBoxNodesTagsAPI.patchById(
        payload.nodeId,
        payload.tags
      );

      const { updateNodeTags } = useNodeTags();
      updateNodeTags({
        nodeId: payload.nodeId,
        tags: payload.fullTags
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      assignTagPending.value = false;
    }
  }

  async function updateNodeTags(payload: { nodeId: string; tags: Tag[] }) {
    try {
      const res = await assignTag({
        nodeId: payload.nodeId,
        tags: payload.tags.map((tag: Tag) => formattedTag(tag)),
        fullTags: payload.tags
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function setNodeTags(payload: {
    nodeId: string;
    data: NodeTagsUpdate;
  }) {
    setNodeTagsPending.value = true;
    try {
      const res = await deepBoxNodesTagsUpdateAPI.patchById(
        payload.nodeId,
        payload.data
      );
      const { updateNodeTags } = useNodeTags();
      updateNodeTags({
        nodeId: payload.nodeId,
        tags: res?.data
      });

      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setNodeTagsPending.value = false;
    }
  }

  // TAG FILTERING
  function setTag(tagKey: string) {
    tag.value = tagKey;
  }

  function resetTag() {
    tag.value = '';
  }

  function resetTempTags() {
    tempTags.value = {} as ListTags;
  }

  return {
    // state
    assignTagPending,
    fetchTagsPending,
    fetchTagsByKeyPending,
    setNodeTagsPending,
    tags,
    tag,
    tempTags,
    // actions
    fetchTags,
    fetchTagsByKey,
    assignTag,
    updateNodeTags,
    setNodeTags,
    setTag,
    resetTag,
    resetTempTags
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeepBoxTagsStore, import.meta.hot));
}
