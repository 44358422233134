import { deepBoxApi } from '@/api/interceptors';
import type { HttpClientResponse, HttpClient } from '@/api/http-client';
import type { NodeMove } from '@/api/types/deepbox/node';

export default class DeepBoxNodesMoveAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  post(
    nodeId: string,
    data?: NodeMove,
    config?: Record<string, unknown>
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.post<never>(`nodes/${nodeId}/move`, data, config);
  }
}

export const deepBoxNodesMoveAPI = new DeepBoxNodesMoveAPIService(deepBoxApi);
