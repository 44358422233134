import type { RouteLocation } from 'vue-router';
import { constants } from '@/constants';
import type { Node } from '@/api/types/deepbox/node';
import { useDeepBoxPreviewStore } from '@/stores/deepbox/deepboxes/preview';
import type { PathSegment } from '@/api/types/deepbox/path';
import { useDeepBoxSearchStore } from '@/stores/deepbox/search';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes';

export function useNode() {
  // stores
  const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore();
  const deepBoxSearchStore = useDeepBoxSearchStore();

  function getCurrentRootNodeFromRoute(currentRoute: RouteLocation) {
    if (
      [
        constants.ROUTE_QUEUE,
        constants.ROUTE_FILES,
        constants.ROUTE_FILES_NODE,
        constants.ROUTE_TRASH,
        constants.ROUTE_TRASH_NODE
      ].includes(currentRoute.name)
    ) {
      return deepBoxDeepBoxesBoxesNodesStore.getCurrentRootNode;
    }

    return null;
  }

  function getNodeById(nodeId: string) {
    let foundNode;
    foundNode = deepBoxDeepBoxesBoxesNodesStore.data?.nodes?.find(
      (node: Node) => node.nodeId === nodeId
    );

    if (foundNode) {
      return foundNode;
    }

    // When not in one of the previous sections search in preview store
    const deepBoxPreviewStore = useDeepBoxPreviewStore();
    foundNode = deepBoxPreviewStore.parentNodeChildren?.nodes?.find(
      (node) => node.nodeId === nodeId
    );

    if (foundNode) {
      return foundNode;
    }

    return null;
  }

  function getOrderFromState(defaultValue: string) {
    if (deepBoxDeepBoxesBoxesNodesStore.params?.order) {
      return deepBoxDeepBoxesBoxesNodesStore.params.order;
    }
    return defaultValue;
  }

  function isNodeTypeFolder(node: Node | PathSegment) {
    return node && node?.type === constants.NODE_TYPE_FOLDER;
  }

  async function refreshNodesByRoute(routeLocation: RouteLocation) {
    switch (routeLocation?.name) {
      case 'organization-types-type-boxes-box-files-node':
        await deepBoxDeepBoxesBoxesNodesStore.fetchBoxFileNodes({
          typeId: routeLocation.params.type,
          boxId: routeLocation.params.box,
          nodeId: routeLocation.params.node
        });
        break;
      case 'organization-types-type-boxes-box-files':
        await deepBoxDeepBoxesBoxesNodesStore.fetchBoxFiles({
          typeId: routeLocation.params.type,
          boxId: routeLocation.params.box
        });
        break;
      case 'organization-types-type-boxes-box-trash-nodes':
        await deepBoxDeepBoxesBoxesNodesStore.fetchBoxTrashNodes({
          typeId: routeLocation.params.type,
          boxId: routeLocation.params.box,
          nodeId: routeLocation.params.node
        });
        break;
      case 'organization-types-type-boxes-box-trash':
        await deepBoxDeepBoxesBoxesNodesStore.fetchBoxTrash({
          typeId: routeLocation.params.type,
          boxId: routeLocation.params.box
        });
        break;
      case 'organization-types-type-boxes-box-inbox':
        await deepBoxDeepBoxesBoxesNodesStore.fetchBoxInbox({
          typeId: routeLocation.params.type,
          boxId: routeLocation.params.box
        });
        break;
      case 'organization-types-type-boxes-box-search':
        await deepBoxSearchStore.refresh();
        break;
      default:
        break;
    }
  }

  return {
    getCurrentRootNodeFromRoute,
    getNodeById,
    getOrderFromState,
    isNodeTypeFolder,
    refreshNodesByRoute
  };
}
