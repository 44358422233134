import { deepBoxApi } from '@/api/interceptors';
import type { HttpClientResponse, HttpClient } from '@/api/http-client';
import type { Node } from '@/api/types/deepbox/node';

export default class DeepBoxNodesConvertAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  post(nodeId: string): Promise<HttpClientResponse<Node>> {
    return this.httpClient.post<Node>(`nodes/${nodeId}/convert`);
  }
}

export const deepBoxNodesConvertAPI = new DeepBoxNodesConvertAPIService(
  deepBoxApi
);
