import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { PathData } from '@/api/types/deepbox/path';
import type { Folder, FolderAdded } from '@/api/types/deepbox/folder';

export default class DeepBoxDeepBoxesBoxesFilesPathAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  postByNodeId(
    deepBoxNodeId: string,
    boxNodeId: string,
    nodeId: string,
    data?: Folder[]
  ): Promise<HttpClientResponse<FolderAdded[]>> {
    return this.httpClient.post<FolderAdded[]>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files/${nodeId}/path`,
      data
    );
  }

  get(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<PathData>> {
    return this.httpClient.get<PathData>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files/path`,
      params,
      options
    );
  }

  getByNodeId(
    deepBoxNodeId: string,
    boxNodeId: string,
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<PathData>> {
    return this.httpClient.get<PathData>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files/${nodeId}/path`,
      params,
      options
    );
  }
}

export const deepBoxDeepBoxesBoxesFilesPathAPI =
  new DeepBoxDeepBoxesBoxesFilesPathAPIService(deepBoxApi);
