<template>
  <v-layout class="layout-onboarding">
    <TheAppBar :is-mobile="display.smAndDown.value" />
    <v-main>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <router-view />
      </div>
    </v-main>
    <!-- Go to top -->
    <DeepFooter />
  </v-layout>
</template>

<script lang="ts" setup>
import { DeepFooter } from '@deepcloud/deep-ui-lib';
import TheAppBar from '@/components/appbar/TheAppBar.vue';
import { useDisplay } from 'vuetify';

const display = useDisplay();
</script>

<style lang="scss" scoped>
.page-wrapper {
  height: 100%;
}
</style>
