<template>
  <v-container class="container pa-0 ma-0 fill-height" fluid>
    <slot></slot>
  </v-container>
</template>

<style scoped lang="scss">
.container {
  max-height: calc(100svh - 64px);
  overflow-y: auto;
}
</style>
