import type { News } from '@/api/types/deepadmin/notifications/news';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const showDrawer = ref(false);

export function useNotificationsNews() {
  const { locale } = useI18n();

  function getCurrentTranslationByItem(item: News) {
    if (!item) return;
    const key = Object.keys(item.translations).filter((k) => {
      return k.startsWith(locale.value);
    });
    if (key.length > 0) {
      return item.translations?.[key[0]];
    }
    return;
  }

  function getCurrentNewsImage(item: News) {
    if (!item) return null;
    const key = `image_${locale.value.substring(0, 2)}`;
    if (Object.keys(item).includes(key)) {
      return item[key];
    }
    return null;
  }

  return {
    showDrawer,
    getCurrentTranslationByItem,
    getCurrentNewsImage
  };
}
