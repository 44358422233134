import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized
} from 'vue-router';
import { captureException } from '@sentry/browser';
// stores
import { useErrorStore } from '@/stores/error/error';
// ROUTES
import invitationRoutes from './routes/invitation';
import linkRoutes from './routes/link';
import watchesRoutes from './routes/watches';
import uploadRoutes from './routes/upload';
// MIDDLEWARES
import routeNotFound from './middleware/route-not-found';
import needsOrganizationContext from './middleware/needs-organization-context';
// LAYOUTS
import LayoutBoxDetail from '@/layouts/LayoutBoxDetail.vue';
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutFilePreview from '@/layouts/LayoutFilePreview.vue';
import LayoutEmpty from '@/layouts/LayoutEmpty.vue';
import LayoutStore from '@/layouts/LayoutStore.vue';
import LayoutError from '@/layouts/LayoutError.vue';
// SERVICES
import { useKeycloak } from '@/composables/auth/use-keycloak';
import { isLocalOrDevEnv } from '@/utils/helpers/env.ts';
import { deepOPortalMappingsAPIService } from '@/api/deepo/portal-mappings.ts';

const routes = [
  {
    path: '/:catchAll(.*)*',
    alias: '/error-page',
    name: 'error-page',
    component: LayoutError,
    meta: {
      auth: false
    },
    props: {
      error: {
        statusCode: 404,
        title: 'error.page_not_found',
        deepBoxError: true
      }
    },
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) =>
      routeNotFound({
        to,
        from,
        next
      })
  },

  {
    path: '/',
    name: 'index',
    component: LayoutDefault,
    redirect: { name: 'organization' },
    meta: {
      needsOrganizationContext: true
    },
    children: [
      {
        path: '/client-apps/',
        component: () => import('@/pages/ClientApps.vue'),
        name: 'client-apps'
      },
      {
        path: '/:organization?',
        component: () =>
          import('@/pages/organization/OrganizationDashboard.vue'),
        name: 'organization'
      }
    ]
  },
  // STORE
  {
    path: '/:organization/store/',
    component: LayoutStore,
    name: 'organization-store',
    redirect: '/:organization/store/services/:service',
    children: [
      {
        path: '/:organization/store/services/:service',
        component: () =>
          import('@/pages/organization/store/service/StoreServiceIndex.vue'),
        name: 'organization-store-services-service',
        props: true
      },
      {
        path: '/:organization/store/services/:service?/process',
        component: () =>
          import('@/pages/organization/store/service/StoreServiceProcess.vue'),
        name: 'organization-store-services-service-process'
      }
    ]
  },
  // ORGANIZATION FILE PREVIEW
  {
    path: '/node/:node/preview',
    component: LayoutFilePreview,
    meta: {
      needsOrganizationContext: true
    },
    children: [
      {
        path: '',
        name: 'node-preview',
        component: () => import('@/pages/node/NodePreview.vue')
      }
    ]
  },
  // OO NODE EDITOR
  {
    path: '/node/:node/edit',
    component: LayoutEmpty,
    children: [
      {
        path: '',
        name: 'node-edit',
        component: () => import('@/pages/node/NodeEdit.vue')
      }
    ]
  },

  // BOX DETAIL
  {
    path: '/:organization/types/:type?/boxes',
    component: LayoutBoxDetail,
    name: 'organization-types-type-boxes-box-layout',
    meta: {
      needsOrganizationContext: true
    },
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      if (isLocalOrDevEnv()) {
        const { organization, type, box } = to.params;
        try {
          const { data } = await deepOPortalMappingsAPIService.get(
            organization,
            {
              deepbox_node_id: type,
              box_node_id: box
            }
          );
          if (data && data.portalUrl) {
            window.open(data.portalUrl, '_self');
            return;
          }
        } finally {
          next();
        }
      }
      next();
    },
    children: [
      {
        path: '/:organization/types/:type?/boxes/:box?',
        component: () => import('@/pages/organization/type/box/BoxIndex.vue'),
        name: 'organization-types-type-boxes-box',
        children: [
          {
            path: 'files',
            component: () =>
              import('@/pages/organization/type/box/files/BoxFilesIndex.vue'),
            name: 'organization-types-type-boxes-box-files'
          },
          {
            path: 'files/:node',
            component: () =>
              import('@/pages/organization/type/box/files/BoxFilesNode.vue'),
            name: 'organization-types-type-boxes-box-files-node'
          },
          {
            path: 'inbox',
            component: () =>
              import('@/pages/organization/type/box/BoxInbox.vue'),
            name: 'organization-types-type-boxes-box-inbox'
          },
          {
            path: 'search',
            component: () =>
              import('@/pages/organization/type/box/BoxSearch.vue'),
            name: 'organization-types-type-boxes-box-search',
            props: true
          },
          {
            path: 'shares',
            component: () =>
              import('@/pages/organization/type/box/BoxShares.vue'),
            name: 'organization-types-type-boxes-box-shares'
          },
          {
            path: 'trash',
            component: () =>
              import('@/pages/organization/type/box/trash/BoxTrashIndex.vue'),
            name: 'organization-types-type-boxes-box-trash'
          },
          {
            path: 'trash/:node',
            component: () =>
              import('@/pages/organization/type/box/trash/BoxTrashNode.vue'),
            name: 'organization-types-type-boxes-box-trash-node'
          }
        ]
      }
    ]
  },
  // INVITATION
  ...invitationRoutes,
  // LINK
  ...linkRoutes,
  // UPLOAD
  // WATCHES
  ...watchesRoutes,
  // UPLOAD
  ...uploadRoutes
];

// Create fn to init the router in order to solve an issue where KC query params stay on the URL
// https://github.com/dsb-norge/vue-keycloak-js/issues/94#issuecomment-1794403391
// https://github.com/keycloak/keycloak/issues/14742#issuecomment-1663069438
const initializeRouter = () => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
    // scrollBehavior(to) {
    //   if (to.params.savePosition) {
    //     return null;
    //   }
    //   return { x: 0, y: 0 };
    // }
  });

  interface LoginOptions {
    redirectUri: string;
    locale?: string;
  }

  router.beforeEach(async (to, from, next) => {
    // ############ CLEAR ERROR IF EXISTS #########
    const errorStore = useErrorStore();
    if (errorStore.error) {
      errorStore.$reset();
    }
    const routeLangQuery = to.query?.lang || '';

    const basePath = window.location.origin;
    const keycloakRedirectUri = basePath + to.fullPath;
    const toRouteNeedsAuth = to.meta.auth !== false;
    const { isAuthenticated, login } = useKeycloak();
    if (toRouteNeedsAuth) {
      if (!isAuthenticated.value) {
        console.log('Route need auth but user is not authenticated');
        const params: LoginOptions = {
          redirectUri: keycloakRedirectUri
        };
        if (routeLangQuery) {
          params.locale = routeLangQuery;
        }

        return login(params);
      } else {
        const paramNeedsOrganizationContext = to.matched.some(
          (m) => m.meta.needsOrganizationContext
        );
        if (paramNeedsOrganizationContext) {
          return needsOrganizationContext({ to, next });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  });

  // Hard-reload the page when chunk load errors match the navigation error
  // https://github.com/vitejs/vite/issues/11804#issuecomment-1760951463
  router.onError((error, to) => {
    const errors = [
      'Failed to fetch dynamically imported module',
      'Unable to preload CSS',
      'error loading dynamically imported module'
    ];

    if (errors.some((e) => error.message.includes(e))) {
      if (to?.fullPath) {
        window.location = to.fullPath;
      } else {
        window.location.reload();
      }
    } else {
      captureException('Triggered automatic reload after router error. ', {
        extra: error.message
      });
    }
  });

  console.log('ROUTER: router initialized');

  return router;
};

export default initializeRouter;
