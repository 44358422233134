<template>
  <div @dragover.prevent="onDragOver">
    <CoreNavigationDrawer
      v-bind="$attrs"
      id="nav-drawer"
      v-model:rail="settingsStore.navDrawerMini"
      permanent
      ref="navDrawer"
      class="core-navigation-drawer"
      data-test-id="box-navigation-drawer"
      absolute
    >
      <!-- TODO(VUE-3-MIGRATION): check the replacement for `expand` props because was removed-->
      <v-list
        nav
        density="compact"
        slim
        :class="settingsStore.navDrawerMini ? '' : 'px-0'"
      >
        <!-- DASHBOARD -->
        <CoreNavigationDrawerListItem
          :title="t('navigation.dashboard')"
          exact
          nav
          :to="{
            name: 'organization',
            params: {
              organization: deepAdminUsersMeStore.selectedOrganizationId
            }
          }"
          data-test-id="box-navigation-dashboard"
        >
          <template #icon>
            <CoreTooltip
              :location="settingsStore.navDrawerMini ? 'right' : 'top'"
            >
              <template #activator="{ props: ActivatorProps }">
                <v-icon v-bind="ActivatorProps" class="mx-0">
                  fad fa-house
                </v-icon>
              </template>
              <span class="text-caption">{{ t('navigation.dashboard') }}</span>
            </CoreTooltip>
          </template>
        </CoreNavigationDrawerListItem>
        <!-- /DASHBOARD -->
        <v-divider class="my-2 mx-2" />
        <!-- MENU ITEMS -->
        <!-- MENU ITEM: INBOX -->
        <CoreNavigationDrawerListItem
          v-if="canListQueue(deepBoxBoxInfoStore.boxInfo)"
          id="inbox"
          icon="far fa-envelope"
          :title="t('box_details.navigation.inbox')"
          exact
          nav
          :to="{
            name: 'organization-types-type-boxes-box-inbox',
            params: {
              organization: route.params.organization,
              type: typeId,
              box: boxId
            }
          }"
          data-test-id="box-navigation-inbox"
          draggable="false"
          @drop="dropNode($event, constants.FILETREE_INBOX)"
          @dragover.prevent="dragOver($event, constants.FILETREE_INBOX)"
          @dragleave.prevent="dragLeave($event, constants.FILETREE_INBOX)"
          @dragenter.prevent
          @icon:dblclick="settingsStore.navDrawerMini = false"
        >
          <template #append>
            <v-badge
              v-if="inboxCount !== undefined"
              color="primary"
              :content="inboxCount"
              :inline="true"
            >
              <v-icon
                v-if="settingsStore.navDrawerMini"
                icon="far fa-envelope"
              ></v-icon>
            </v-badge>
          </template>
          <template #icon v-if="settingsStore.navDrawerMini">
            <v-badge
              v-if="inboxCount !== undefined"
              :content="inboxCount"
              color="primary"
            >
              <v-icon icon="far fa-envelope"></v-icon>
            </v-badge>
          </template>
        </CoreNavigationDrawerListItem>
        <!-- /MENU ITEM: INBOX -->
        <!-- /MENU ITEM: FILES -->
        <CoreNavigationDrawerListItem
          v-if="canListFilesRoot(deepBoxBoxInfoStore.boxInfo)"
          :id="constants.FILETREE_FILES"
          exact
          :title="t('box_details.navigation.files')"
          icon="far fa-folder"
          color="primary"
          :to="{
            name: 'organization-types-type-boxes-box-files',
            params: {
              organization: route.params.organization,
              type: typeId,
              box: boxId
            }
          }"
          draggable="false"
          data-test-id="box-navigation-files"
          @drop="dropNode($event, constants.FILETREE_FILES)"
          @dragover.prevent="dragOver($event, constants.FILETREE_FILES)"
          @dragleave.prevent="dragLeave($event, constants.FILETREE_FILES)"
          @dragenter.prevent
          @icon:dblclick="settingsStore.navDrawerMini = false"
        >
          <template v-if="!settingsStore.navDrawerMini" #icon-prepend>
            <v-btn
              class="mr-2"
              slim
              icon
              size="small"
              density="compact"
              variant="text"
              :loading="
                deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTreePending
              "
              @click.prevent="
                deepBoxCoreStore.drawerDisplayTree =
                  !deepBoxCoreStore.drawerDisplayTree
              "
            >
              <template #loader>
                <v-icon class="fa-spin" size="x-small">far fa-loader</v-icon>
              </template>
              <v-icon size="x-small">
                {{
                  deepBoxCoreStore.drawerDisplayTree
                    ? 'far fa-angle-down'
                    : 'far fa-angle-right'
                }}
              </v-icon>
            </v-btn>
          </template>
        </CoreNavigationDrawerListItem>
        <BoxNavigationTreeView
          v-if="
            deepBoxCoreStore.drawerDisplayTree &&
            canListFilesRoot(deepBoxBoxInfoStore.boxInfo)
          "
          :organization-id="route.params.organization"
          :type-id="typeId"
          :box-id="boxId"
          @item:drop="dropNode($event.event, $event.item)"
          @item:dragover="dragOver($event.event, $event.item)"
          @item:dragleave="dragLeave($event.event, $event.item)"
          @item:dragstart="dragStart($event.event, $event.item)"
          @item:dragend="dragDrop.onDragEnd"
        />
        <!-- /MENU ITEM: FILES -->
        <!-- MENU ITEM: TRASH -->
        <CoreNavigationDrawerListItem
          v-if="canAccessTrash(deepBoxBoxInfoStore.boxInfo)"
          id="trash"
          icon="far fa-trash"
          :title="t('box_details.navigation.trash')"
          exact
          nav
          :to="{
            name: 'organization-types-type-boxes-box-trash',
            params: {
              organization: route.params.organization,
              type: typeId,
              box: boxId
            }
          }"
          data-test-id="box-navigation-trash"
          draggable="false"
          @drop="dropNode($event, constants.FILETREE_TRASH)"
          @dragover="dragOver($event, constants.FILETREE_TRASH)"
          @dragleave="dragLeave($event, constants.FILETREE_TRASH)"
          @dragenter.prevent
          @click="treeViewActive = []"
          @icon:dblclick="settingsStore.navDrawerMini = false"
        />
        <!-- /MENU ITEM: TRASH -->
        <template v-if="isOwnedBox">
          <v-divider class="my-2 mx-2" />
          <!-- MENU ITEM: DEADLINES -->
          <CoreNavigationDrawerListItem
            v-if="canSeeDeadlines"
            icon="far fa-calendar-clock"
            :title="t('box_details.navigation.deadlines')"
            exact
            nav
            :to="{
              name: 'organization-types-type-boxes-box-search',
              params: {
                organization: route.params.organization,
                type: typeId,
                box: boxId
              },
              query: { tags: 'deadline' }
            }"
            data-test-id="box-navigation-deadlines"
            @click="searchDeadlineItems"
            @icon:dblclick="settingsStore.navDrawerMini = false"
          />
          <!-- /MENU ITEM: DEADLINES -->
          <!-- MENU ITEM: SHARED (only visible on local or dev) -->
          <CoreNavigationDrawerListItem
            v-if="settingsDevStore.canModeFeature('DEEP_BOX_SHARES')"
            icon="far fa-users"
            :title="t('box_details.navigation.shares')"
            exact
            nav
            :to="{
              name: 'organization-types-type-boxes-box-shares',
              params: {
                organization: route.params.organization,
                type: typeId,
                box: boxId
              }
            }"
            data-test-id="box-navigation-shares"
            @icon:dblclick="settingsStore.navDrawerMini = false"
          >
            <template v-if="!settingsStore.navDrawerMini" #append>
              <DeepStageLabel flag="WIP" size="x-small" display-as="chip" />
            </template>
          </CoreNavigationDrawerListItem>
          <!-- /MENU ITEM: SHARED -->

          <!-- MENU ITEM: RELATIONS -->
          <div
            v-if="
              deepBoxBoxInfoStore.boxInfo &&
              deepBoxBoxInfoStore.boxInfo.relations.length > 0
            "
          >
            <v-divider class="my-2 mx-2" />
            <CoreNavigationDrawerListItem
              v-for="boxRelation in deepBoxBoxInfoStore.boxInfo.relations"
              :key="boxRelation.boxNodeId"
              :title="boxRelation.boxName"
              :subtitle="boxRelation.deepBoxName"
              exact
              nav
              :to="{
                name: 'organization-types-type-boxes-box-files',
                params: {
                  organization: route.params.organization,
                  type: boxRelation.deepBoxNodeId,
                  box: boxRelation.boxNodeId
                }
              }"
              :data-test-id="`box-navigation-relation-${boxRelation.boxName}`"
            >
              <template #icon-content="{ activator }">
                <BoxAvatar
                  v-bind="activator.props"
                  box-size="22"
                  class="mr-1"
                />
              </template>
            </CoreNavigationDrawerListItem>
          </div>
          <!-- /MENU ITEM: RELATIONS -->

          <!-- /MENU ITEMS -->
        </template>
      </v-list>

      <template #append-list-item-prepend>
        <CoreNavigationDrawerListItem
          v-if="settingsDevStore.canModeFeature('DEEP_FLOW')"
          icon="far fa-diagram-project"
          exact
          nav
          title="DeepFlow"
          :href="deepFlowHref"
          target="_blank"
          data-test-id="box-navigation-deepbox-settings"
        >
          <template v-if="!settingsStore.navDrawerMini" #append>
            <DeepStageLabel flag="DEV" size="x-small" display-as="chip" />
          </template>
        </CoreNavigationDrawerListItem>
        <CoreNavigationDrawerListItem
          v-if="canAccessDeepOSettings"
          icon="far fa-gear"
          exact
          nav
          :title="t('navigation.deepo_settings')"
          :href="deepoSettingsHref"
          target="_blank"
          data-test-id="box-navigation-deepbox-settings"
          @icon:dblclick="settingsStore.navDrawerMini = false"
        />
        <CoreNavigationDrawerListItem
          v-if="canAdminAccess"
          v-bind="adminBoxUrl"
          icon="far fa-gear"
          exact
          nav
          :title="t('navigation.deepbox_settings')"
          data-test-id="box-navigation-deepbox-settings"
          @icon:dblclick="settingsStore.navDrawerMini = false"
        />
      </template>
    </CoreNavigationDrawer>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onUnmounted, ref, watch } from 'vue';
import isEmpty from 'lodash/isEmpty';
import { DeepStageLabel } from '@deepcloud/deep-ui-lib';
import CoreNavigationDrawer from '@/components/core/CoreNavigationDrawer.vue';
import CoreNavigationDrawerListItem from '@/components/core/CoreNavigationDrawerListItem.vue';
import CoreTooltip from '@/components/core/CoreTooltip.vue';
import { constants } from '@/constants/constants';
import { useSettingsStore } from '@/stores/settings/settings';
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { useDeepBoxSearchStore } from '@/stores/deepbox/search';
import { useDeepBoxDeepBoxesBoxesFilesTreeStore } from '@/stores/deepbox/deepboxes/boxes/files-tree';
import { useDeepBoxAdminStore } from '@/stores/deepbox/admin/admin';
import { useDragDrop } from '@/composables/use-drag-drop';
import { useDeepBoxCoreStore } from '@/stores/deepbox/core';
import { useSettingsDevStore } from '@/stores/settings/settings-dev';
import BoxAvatar from '@/components/box/BoxAvatar.vue';
import { useDisplay } from 'vuetify';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import BoxNavigationTreeView from '@/components/box/BoxNavigationTreeView.vue';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes';
import { deepBoxDeepBoxesBoxesTrashAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-trash.ts';
import { deepBoxDeepBoxesBoxesQueueAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-queue.ts';
import { deepBoxDeepBoxesBoxesFilesAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-files.ts';
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts';
import { useNodeMimeType } from '@/composables/use-node-mime-type.ts';
import { useBoxPolicy } from '@/composables/use-box-policy.ts';
import type { FileTree } from '@/types/file-tree.ts';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const props = defineProps({
  typeId: {
    type: String,
    default: null
  },
  boxId: {
    type: String,
    default: null
  },
  organizationId: {
    type: String,
    default: null
  }
});

const display = useDisplay();
const device = inject(DeviceKey);
const { t } = useI18n();
const route = useRoute();

const deepBoxAdminStore = useDeepBoxAdminStore();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
const deepBoxCoreStore = useDeepBoxCoreStore();
const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore();
const deepBoxDeepBoxesBoxesFilesTreeStore =
  useDeepBoxDeepBoxesBoxesFilesTreeStore();
const settingsStore = useSettingsStore();
const deepBoxSearchStore = useDeepBoxSearchStore();
const settingsDevStore = useSettingsDevStore();
const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore();

const { isNodeMimeTypeInbox, isNodeMimeTypeGeneric, isNodeMimeTypeFolder } =
  useNodeMimeType();

const currentLongHoverId = ref('');
const currentLongHoverTimeout = ref<ReturnType<typeof setTimeout>>(0);
const longHoverDelay = ref(400);
const offset = ref(0);
const limit = ref(50);
const treeViewActive = ref([]);

onUnmounted(() => {
  deepBoxDeepBoxesBoxesFilesTreeStore.$reset();
});

const deepoSettingsHref = computed(
  () =>
    `${import.meta.env.VITE_DEEPBOX_EDITOR_BASE_URL}${
      props.organizationId
    }/masterdata/types/${props.typeId}/boxes/${props.boxId}`
);

const deepFlowHref = computed(() => {
  const url = new URL(import.meta.env.VITE_DEEPFLOW_FRONTEND_BASE_URL);

  url.searchParams.append('orgId', props.organizationId);
  url.searchParams.append('typeId', props.typeId);
  url.searchParams.append('boxId', props.boxId);
  return url.toString();
});

const adminBoxUrl = computed(() => {
  const url = new URL(
    `${
      import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL
    }organizations/${props.organizationId}/boxes`
  );
  url.searchParams.append(
    'iframe_path',
    `/hybrid/organization/${props.organizationId}/deepbox/${props.typeId}/boxes/${props.boxId}`
  );

  return {
    href: url.toString(),
    target: '_self'
  };
});

const { canListFilesRoot, canAccessTrash, canListQueue } = useBoxPolicy();

const inboxCount = computed(() => deepBoxBoxInfoStore.boxInfo?.queueCount);
const canSeeDeadlines = computed(() =>
  deepBoxBoxInfoStore.boxInfo?.supportedFeatureTags?.includes(
    constants.SUPPORTED_FEATURE_TAGS.DEADLINE
  )
);

const isOwnedBox = computed(() => {
  if (!isEmpty(deepBoxCoreStore.selectedBox?.company)) {
    return (
      deepBoxCoreStore.selectedBox?.company.companyId ===
      deepAdminUsersMeStore.selectedOrganizationId
    );
  }
  return false;
});

const canAccessDeepOSettings = computed(
  () =>
    deepBoxBoxInfoStore.boxInfo &&
    deepBoxBoxInfoStore.boxInfo.boxVariant &&
    [
      constants.BOX_VARIANT_ADVANCED,
      constants.BOX_VARIANT_ADVANCED_PER_USER
    ].includes(deepBoxBoxInfoStore.boxInfo.boxVariant) &&
    canAdminAccess.value
);

const canAdminAccess = computed(
  () => deepBoxAdminStore.adminBox?.boxPolicy?.canAdminAccess || false
);

watch(
  () => settingsStore.navDrawerMini,
  (newValue) => {
    if (newValue) {
      deepBoxCoreStore.drawerDisplayTree = false;
    }
  }
);

watch(
  () => display.mdAndUp.value,
  (newValue) => {
    if (!newValue && settingsStore.navDrawerMini) {
      settingsStore.navDrawerMini = false;
    }
  }
);

watch(
  () => device.isDesktop,
  (newValue) => {
    if (newValue && settingsStore.navDrawerMini) {
      settingsStore.navDrawerMini = false;
    }
  }
);

watch(
  () => deepBoxCoreStore.drawerDisplayTree,
  (newValue) => {
    if (newValue) {
      loadFilesTree();
    }
  }
);

async function loadFilesTree() {
  await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTree({
    typeId: props.typeId,
    boxId: props.boxId
  });
  deepBoxCoreStore.drawerDisplayTree = true;
}

function getElementById(nodeId: string) {
  const selector = document.querySelector(
    `.v-navigation-drawer__content [id='${nodeId}']`
  );
  if (selector) {
    return selector as HTMLElement;
  }

  return undefined;
}

function getParentById(nodeId: string) {
  return getElementById(nodeId);
}

function getNodeRoots() {
  if (!props.typeId || !props.boxId) return;
  const promises = [
    // inbox
    deepBoxDeepBoxesBoxesQueueAPI.get(props.typeId, props.boxId, { limit: 0 }),
    // files
    deepBoxDeepBoxesBoxesFilesAPI.get(props.typeId, props.boxId, { limit: 0 }),
    // trash
    deepBoxDeepBoxesBoxesTrashAPI.get(props.typeId, props.boxId, { limit: 0 })
  ];

  Promise.all(promises).then((responses) => {
    responses.forEach(({ data }) => {
      const rootNode = data.path.segments[0];
      switch (rootNode.name) {
        case constants.SECTION_INBOX:
        case constants.SECTION_QUEUE:
          deepBoxDeepBoxesBoxesNodesStore.rootNodes.queue =
            data?.path?.segments[0];
          break;

        case constants.SECTION_FILES:
          deepBoxDeepBoxesBoxesNodesStore.rootNodes.files =
            data?.path?.segments[0];
          break;

        case constants.SECTION_TRASH:
          deepBoxDeepBoxesBoxesNodesStore.rootNodes.trash =
            data?.path?.segments[0];
          break;
      }
    });
  });
}

const hasTypeAndBoxIds = computed(() => props.typeId && props.boxId);

watch(
  () => hasTypeAndBoxIds.value,
  () => {
    getNodeRoots();
  },
  {
    immediate: true
  }
);

async function getNodeAndParent(item: string | FileTree) {
  let element;

  interface Output {
    node: FileTree | undefined;
    parent: HTMLElement | undefined;
  }

  const output: Output = {
    node: undefined,
    parent: undefined
  };

  if (typeof item !== 'string') {
    output.node = item;
    element = getElementById(item.nodeId);
  } else {
    switch (item) {
      case constants.SECTION_INBOX:
        if (deepBoxDeepBoxesBoxesNodesStore.rootNodes.queue) {
          output.node = deepBoxDeepBoxesBoxesNodesStore.rootNodes.queue;
        } else {
          const res = await deepBoxDeepBoxesBoxesQueueAPI.get(
            props.typeId,
            props.boxId
          );
          deepBoxDeepBoxesBoxesNodesStore.rootNodes.queue =
            res.data?.path?.segments[0];
        }
        break;
      case constants.SECTION_FILES:
        if (deepBoxDeepBoxesBoxesNodesStore.rootNodes.files) {
          output.node = deepBoxDeepBoxesBoxesNodesStore.rootNodes.files;
        } else {
          const res = await deepBoxDeepBoxesBoxesFilesAPI.get(
            props.typeId,
            props.boxId
          );
          deepBoxDeepBoxesBoxesNodesStore.rootNodes.files =
            res.data?.path?.segments[0];
        }
        break;
      case constants.SECTION_TRASH:
        if (deepBoxDeepBoxesBoxesNodesStore.rootNodes.trash) {
          output.node = deepBoxDeepBoxesBoxesNodesStore.rootNodes.trash;
        } else {
          const res = await deepBoxDeepBoxesBoxesTrashAPI.get(
            props.typeId,
            props.boxId
          );
          deepBoxDeepBoxesBoxesNodesStore.rootNodes.trash =
            res.data?.path?.segments[0];
        }
        break;

      // no default
    }
    element = getElementById(item);
  }

  // if the node is a folder, get its root parent element
  if (
    output.node?.mimeType &&
    (isNodeMimeTypeGeneric(output.node) || isNodeMimeTypeFolder(output.node))
  ) {
    if (output.node?.nodeId) {
      output.parent = getParentById(output.node?.nodeId);
    }
  } else {
    // otherwise, use the element itself (files, inbox and trash nodes)
    output.parent = element;
  }

  return output;
}

const dragDrop = useDragDrop();

async function dragOver(event: DragEvent, item: string | FileTree) {
  if (dragDrop.isNodeOverLocked.value) {
    return;
  }

  dragDrop.isNodeOverLocked.value = true;
  const { node, parent } = await getNodeAndParent(item);
  dragDrop.isNodeOverLocked.value = false;

  if (node && parent) {
    dragDrop.onDragOver(event, node, parent);
  }

  longHoverStart(item);
}

async function dragLeave(event: DragEvent, item: string | FileTree) {
  if (dragDrop.isNodeOverLocked.value) {
    return;
  }

  dragDrop.isNodeOverLocked.value = true;
  const { node, parent } = await getNodeAndParent(item);
  dragDrop.isNodeOverLocked.value = false;
  if (node && parent) {
    dragDrop.onDragLeave(event, node, parent);
  }

  longHoverLeave();
}

async function dragStart(e: DragEvent, item: FileTree) {
  dragDrop.onDragStartNodes(e, [item], item.parentNodeId);
}

async function dropNode(event: DragEvent, item: string | FileTree) {
  if (dragDrop.isNodeOverLocked.value) {
    return;
  }

  dragDrop.isNodeOverLocked.value = true;
  const { node, parent } = await getNodeAndParent(item);
  dragDrop.isNodeOverLocked.value = false;
  if (parent) {
    parent.classList.remove(dragDrop.TREE_ITEM_HOVER_CLASS);
  }
  if (!node) return;

  try {
    await dragDrop.onDropNode(event, node);
    // update BoxInfo `queueCount` if drop a node in the `inbox`
    if (isNodeMimeTypeInbox(node) && props.typeId && props.boxId) {
      await deepBoxBoxInfoStore.updateBoxInfoQueueCounterByAPI(
        props.typeId,
        props.boxId
      );
    }
  } finally {
    deepBoxDeepBoxesBoxesNodesStore.selectedNodeIds = [];
  }
}

function longHoverStart(item: string | FileTree) {
  const id = typeof item === 'string' ? item : item.nodeId;

  if (id === currentLongHoverId.value) {
    return;
  }

  currentLongHoverId.value = id;
  if (currentLongHoverTimeout.value) {
    clearTimeout(currentLongHoverTimeout.value);
  }
  currentLongHoverTimeout.value = setTimeout(
    expandAfterLongHover,
    longHoverDelay.value
  );
}

function longHoverLeave() {
  currentLongHoverId.value = '';
  if (currentLongHoverTimeout.value) {
    clearTimeout(currentLongHoverTimeout.value);
  }
}

function expandAfterLongHover() {
  if (!currentLongHoverId.value) return;
  // Ignore long hover for inbox and trash elements
  if (
    [constants.FILETREE_INBOX, constants.FILETREE_TRASH].includes(
      currentLongHoverId.value
    )
  ) {
    return;
  }

  // if the hover element is the 'Files' element, load the files tree (it will load the first time only)
  if (currentLongHoverId.value === constants.FILETREE_FILES) {
    if (!deepBoxCoreStore.drawerDisplayTree) {
      deepBoxCoreStore.drawerDisplayTree = true;
    }
    return;
  }
}

function searchDeadlineItems() {
  deepBoxSearchStore.fetchSearchResults({
    nodeId: props.boxId,
    offset: offset.value,
    limit: limit.value,
    tags: 'deadline'
  });
}

function onDragOver() {
  if (settingsStore.navDrawerMini) {
    settingsStore.navDrawerMini = false;
  }
}
</script>
