import type { BoxInfo } from '@/api/types/deepbox/box';

export function useBoxPolicy() {
  function canAccessTrash(boxInfo: BoxInfo) {
    return boxInfo?.boxPolicy?.canAccessTrash || false;
  }

  function canAddFilesRoot(boxInfo: BoxInfo) {
    return boxInfo?.boxPolicy?.canAddFilesRoot || false;
  }

  function canAddQueue(boxInfo: BoxInfo) {
    return boxInfo?.boxPolicy?.canAddQueue || false;
  }

  function canListFilesRoot(boxInfo: BoxInfo) {
    return boxInfo?.boxPolicy?.canListFilesRoot || false;
  }

  function canListQueue(boxInfo: BoxInfo) {
    return boxInfo?.boxPolicy?.canListQueue || false;
  }

  return {
    canAccessTrash,
    canAddFilesRoot,
    canAddQueue,
    canListFilesRoot,
    canListQueue
  };
}
