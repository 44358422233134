import LayoutInvitation from '@/layouts/LayoutInvitation.vue';

export default [
  {
    path: '/invitation/',
    component: LayoutInvitation,
    name: 'invitation',
    redirect: 'invitation-id',
    meta: {
      auth: false
    },
    children: [
      {
        path: '/invitation/:id',
        component: () => import('@/pages/invitation/InvitationIndex.vue'),
        name: 'invitation-id',
        meta: {
          auth: false
        }
      }
    ]
  }
];
