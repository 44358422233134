import { deepBoxApi } from '@/api/interceptors';
import type { HttpClientResponse, HttpClient } from '@/api/http-client';

export default class DeepBoxDeepBoxesBoxesFavoritesAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  post(
    deepBoxNodeId: string,
    boxNodeId: string
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.post<never>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/favorites`
    );
  }

  deleteById(
    deepBoxNodeId: string,
    boxNodeId: string
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.delete<never>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/favorites`
    );
  }
}

export const deepBoxDeepBoxesBoxesFavoritesAPI =
  new DeepBoxDeepBoxesBoxesFavoritesAPIService(deepBoxApi);
