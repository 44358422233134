import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';

export default class DeepBoxNodesThumbnailUrlAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<string>> {
    return this.httpClient.get<string>(
      `nodes/${nodeId}/thumbnailUrl`,
      params,
      options
    );
  }
}

export const deepBoxNodesThumbnailUrlAPI =
  new DeepBoxNodesThumbnailUrlAPIService(deepBoxApi);
