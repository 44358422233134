<template>
  <!-- MENU -->
  <v-menu v-model="menu" :close-on-content-click="false">
    <!-- MENU BUTTON -->
    <template #activator="{ props: ActivatorProps }">
      <v-btn
        v-bind="ActivatorProps"
        :ripple="false"
        variant="text"
        class="px-0 mx-2"
        data-test-id="box-navigation-selection"
      >
        <BoxItemList
          v-if="deepBoxCoreStore.selectedBox"
          :box="deepBoxCoreStore.selectedBox"
          :is-shared="
            boxHelper.isShared(
              deepBoxCoreStore.selectedBox,
              deepAdminUsersMeStore.selectedOrganizationId
            )
          "
          is-box-selection-menu
          show-box-name-subtitle
        >
          <template #append>
            <v-icon
              color="grey"
              class="pa-0 ma-0"
              :class="device.isTablet ? 'ml-0' : 'ml-1'"
            >
              {{ menu ? 'far fa-angle-up' : 'far fa-angle-down' }}
            </v-icon>
          </template>
        </BoxItemList>
      </v-btn>
    </template>
    <!-- /MENU BUTTON -->

    <!-- MENU CARD -->
    <v-card class="pa-4" min-width="300">
      <v-card-text v-if="showMenuLoading" class="d-flex justify-center">
        <v-progress-circular :size="20" color="grey" width="2" indeterminate />
      </v-card-text>
      <template v-else>
        <v-card-title class="d-flex px-1 py-0 pt-2">
          <v-text-field
            v-model="search"
            :placeholder="t('placeholders.search_box')"
            autofocus
            density="compact"
            hide-details
            clearable
            :loading="isApiLoading"
            name="field-search-box"
          />
        </v-card-title>
        <v-card-text
          class="pa-2"
          :style="{
            maxHeight: xs ? '430px' : '530px',
            overflowY: 'scroll'
          }"
        >
          <v-list slim variant="text" class="pa-0">
            <template v-if="hasBoxes">
              <!-- TYPES BOXES -->
              <div v-for="type in typeBoxes" :key="type.deepBoxNodeId">
                <!-- TITLE -->
                <div
                  v-if="type.boxes.length > 0"
                  class="d-flex flex-row align-center pb-0 mt-2"
                >
                  <span class="box-title">{{ type.deepBoxName }}</span>
                </div>

                <BoxItemList
                  v-for="typeBox in type.boxes"
                  :key="`box-${type.deepBoxNodeId}-${typeBox.boxNodeId}`"
                  :box="typeBox"
                  is-box-selection-menu
                  class="pl-1"
                  :to="{
                    name: 'organization-types-type-boxes-box-files',
                    params: {
                      organization:
                        deepAdminUsersMeStore.selectedOrganizationId,
                      type: typeBox.deepBoxNodeId,
                      box: typeBox.boxNodeId
                    }
                  }"
                  :append-icon="
                    deepBoxCoreStore.selectedBox &&
                    typeBox.boxNodeId === deepBoxCoreStore.selectedBox.boxNodeId
                      ? 'far fa-check'
                      : undefined
                  "
                  :data-test-id="`box-item-${typeBox.boxName}`"
                  @click="selectBox(typeBox)"
                />
                <div
                  v-if="type.size > type.boxes.length"
                  class="mt-1 d-flex justify-end"
                >
                  <v-btn
                    variant="text"
                    :loading="deepBoxOverviewStore.fetchNextBoxesPending"
                    @click="showMoreBoxesByType(type)"
                  >
                    {{ t('actions.more') }}
                  </v-btn>
                </div>
                <!-- /ITEM -->
              </div>
              <!-- /TYPES BOXES -->

              <!-- SHARED BOXES -->
              <div v-if="sharedBoxes.length > 0" class="mt-4">
                <div class="d-flex flex-row align-center">
                  <v-icon end class="mr-3" color="primary">
                    far fa-user-group
                  </v-icon>
                  <span class="box-title"
                    >{{ t('main_view.boxes_shared_with_me') }}
                  </span>
                </div>

                <BoxItemList
                  v-for="sharedBox in sharedBoxes.slice(0, 6)"
                  :key="`share-${sharedBox.deepBoxNodeId}-${sharedBox.boxNodeId}`"
                  :box="sharedBox"
                  is-shared
                  is-box-selection-menu
                  :show-box-name-subtitle="hasMoreBoxesFromSameOrg(sharedBox)"
                  :to="{
                    name: 'organization-types-type-boxes-box-files',
                    params: {
                      organization:
                        deepAdminUsersMeStore.selectedOrganizationId,
                      type: sharedBox.deepBoxNodeId,
                      box: sharedBox.boxNodeId
                    }
                  }"
                  class="pl-1"
                  :append-icon="
                    deepBoxCoreStore.selectedBox &&
                    sharedBox.boxNodeId ===
                      deepBoxCoreStore.selectedBox.boxNodeId
                      ? 'far fa-check'
                      : undefined
                  "
                  @click="selectBox(sharedBox)"
                />

                <div v-if="sharedBoxes.length > 6" class="mt-1">
                  <v-btn variant="text" :to="toHome" @click="onGoToHome">
                    {{ t('actions.more') }}
                  </v-btn>
                </div>
              </div>
              <!-- /SHARED BOXES -->
            </template>
            <v-list-item
              v-else
              class="pa-0"
              :title="t('messages.no_boxes_found')"
            />
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" :to="toHome" @click="onGoToHome">
            {{ t('main_view.all') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <!-- MENU CARD -->
  </v-menu>
  <!-- MENU -->
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch } from 'vue';

// components
import BoxItemList from '@/components/box/BoxItemList.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useBoxHelper } from '@/components/appbar/use-box-helper.ts';
import { toast } from 'vue-sonner';

// stores
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { useDeepBoxOverviewStore } from '@/stores/deepbox/overview';
import { useDeepBoxCoreStore } from '@/stores/deepbox/core';

// apis
import { deepBoxOverviewAPI } from '@/api/deepbox/overview/overview.ts';

// types & constants
import type { BoxEntry } from '@/api/types/deepbox/box';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import type { DeepBoxOverview } from '@/api/types/deepbox/deep-box.ts';
import type { OverviewQueryParams } from '@/api/types/deepbox/overview.ts';

// utilities
import debounce from 'lodash/debounce';
import helpers from '@/utils/helpers.ts';
import { useDisplay } from 'vuetify';

const { t } = useI18n();
const { xs } = useDisplay();

const device = inject(DeviceKey);

const deepBoxOverviewStore = useDeepBoxOverviewStore();
const deepBoxCoreStore = useDeepBoxCoreStore();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
const menu = ref(false);
const search = ref('');
const totalInitialBoxes = ref(0);

const typeBoxes = ref<DeepBoxOverview[]>([]);
const sharedBoxes = ref<BoxEntry[]>([]);

const hasBoxes = computed(() => {
  if (
    typeBoxes.value &&
    typeBoxes.value.length === 0 &&
    sharedBoxes.value &&
    sharedBoxes.value.length === 0
  ) {
    return false;
  }
  if (sharedBoxes.value.length > 0) {
    return true;
  }
  const typesWithBoxes = typeBoxes.value.filter(
    (type) => type?.boxes?.length > 0
  );
  return typesWithBoxes.length > 0;
});

const boxHelper = useBoxHelper();

const toHome = computed(() => {
  return {
    name: 'organization',
    params: {
      organization: deepAdminUsersMeStore.selectedOrganizationId
    }
  };
});

const isApiLoading = ref(false);

async function onFetchOverview(companyId: string, query?: string) {
  isApiLoading.value = true;
  try {
    const params: OverviewQueryParams = {
      'deepBoxes.boxes.limit': 10,
      companyId
    };
    if (query) {
      params.q = query;
    }
    const res = await deepBoxOverviewAPI.get(params);

    typeBoxes.value = [...helpers.typesSortAlphaNum(res.data.deepBoxes)];
    typeBoxes.value.forEach((type) => {
      type.boxes = [...helpers.boxSortAlphaNum(type.boxes)];
    });
    sharedBoxes.value = [
      ...helpers.boxSortAlphaNum(res.data.sharedWithMe.boxes)
    ];
  } catch (e) {
    console.error(e);
    toast.error(t('error.error_occurred'));
  } finally {
    isApiLoading.value = false;
  }
}

const showMenuLoading = ref(false);

async function fetchInitial() {
  showMenuLoading.value = true;
  await onFetchOverview(
    deepAdminUsersMeStore.selectedOrganizationId,
    search.value
  );
  getInitialTotalBoxes();
  showMenuLoading.value = false;
}

watch(
  () => menu.value,
  (newValue) => {
    if (newValue) {
      fetchInitial();
    }
  }
);

watch(
  () => search.value,
  (newValue) => {
    searchBoxesDebounced(newValue);
  }
);

const searchBoxesDebounced = debounce(function (search) {
  onFetchOverview(deepAdminUsersMeStore.selectedOrganizationId, search);
}, 500);

function getInitialTotalBoxes() {
  let total = 0;

  total += typeBoxes.value
    .flatMap((type) => type.size)
    .reduce((a, b) => a + b, 0);

  total += sharedBoxes.value.length;

  totalInitialBoxes.value = total;
}

function selectBox(box: BoxEntry) {
  boxHelper.selectBox(box);
  menu.value = false;
  deepBoxCoreStore.drawerDisplayTree = false;
}

function onGoToHome() {
  // be sure that displayTree is closed when go to home
  deepBoxCoreStore.drawerDisplayTree = false;
}

function hasMoreBoxesFromSameOrg(item: BoxEntry) {
  const orgId = item?.company?.companyId;
  const foundOrganizations = [...sharedBoxes.value].filter(
    (box) => box?.company?.companyId === orgId
  );

  return foundOrganizations.length > 1;
}

function showMoreBoxesByType(type: DeepBoxOverview) {
  deepBoxOverviewStore.fetchNextBoxes({ type });
}

onMounted(() => {
  getInitialTotalBoxes();
});
</script>
<style scoped lang="scss">
.box-title {
  font-weight: 600;
}

.v-list-item--link:before {
  background-color: transparent;
}
</style>
