import type { IDeepBoxError, IDeepBoxErrorBtn } from '@/api/types/error';

export class DeepBoxError extends Error {
  title?: string;
  description?: string;
  img?: string;
  statusCode?: string | number;
  hideStatusCode?: boolean;
  deepBoxError?: boolean;
  btn?: IDeepBoxErrorBtn;

  constructor(message?: string, options?: Partial<IDeepBoxError>) {
    super(message);
    this.name = 'DeepBoxError';
    this.title = options?.title;
    this.description = options?.description;
    this.img = options?.img;
    this.statusCode = options?.statusCode;
    this.hideStatusCode = options?.hideStatusCode;
    this.deepBoxError = true;
    this.btn = options?.btn;
  }
}
