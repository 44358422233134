import { deepAdminApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { CompanyAccounting } from '@/api/types/deepadmin/users/users-company-accounting';

export default class DeepAdminUsersCompanyAccountingAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    organizationId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<CompanyAccounting>> {
    return this.httpClient.get<CompanyAccounting>(
      `users/company/${organizationId}/accounting`,
      params,
      options
    );
  }
}

export const deepAdminUsersCompanyAccountingAPI =
  new DeepAdminUsersCompanyAccountingAPIService(deepAdminApi);
