<template>
  <v-navigation-drawer
    v-model="showDrawer"
    ref="drawerRef"
    :width="drawerWidth"
    location="right"
    temporary
    :touchless="false"
    disable-route-watcher
  >
    <v-toolbar flat height="52">
      <v-app-bar-nav-icon v-if="smAndUp">
        <v-btn
          :icon="isDefaultWidth ? 'far fa-angles-left' : 'far fa-angles-right'"
          @click="onSetToggleWidth"
        >
        </v-btn>
      </v-app-bar-nav-icon>
      <v-spacer />
      <v-toolbar-title>
        <div class="d-inline-flex text-center justify-center align-center">
          <v-img
            class="deepbox-logo"
            src="/svg/logos/deepa_logo.svg"
            width="60"
            position="center"
          />
          <template v-if="!isDefaultWidth">
            <v-divider vertical class="ma-2" />
            <span class="text-h6"> DeepCloud Assistent </span>
          </template>
        </div>
      </v-toolbar-title>
      <v-spacer />

      <DeepAChatOptionsMenu
        :current-locale="
          currentLang ? currentLang.toLowerCase().substring(0, 2) : undefined
        "
        :current-model="currentModel"
        @click:item="onDeepAChatOptionClick"
      />

      <v-btn icon="$close" @click="showDrawer = false"></v-btn>
    </v-toolbar>
    <DeepAChat
      :chat-id="CHAT_ID"
      :model="currentModel"
      :lang="currentLang"
      style="height: calc(100svh - 64px)"
    />
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import DeepAChat from '@/components/deepa/DeepAChat.vue';
import { useDeepaChatDrawer } from '@/components/deepa/use-deepa-chat-drawer';
import { useI18n } from 'vue-i18n';
import DeepAChatOptionsMenu from '@/components/deepa/DeepAChatOptionsMenu.vue';
import { useDeepaChatOptions } from '@/components/deepa/use-deepa-chat-options';
import { useDisplay } from 'vuetify';

const { locale } = useI18n();

const deepaChatDrawer = useDeepaChatDrawer();

const { currentLang, currentModel, onDeepAChatOptionClick } =
  useDeepaChatOptions({
    model: 'ARTIFICIALY',
    lang: locale.value
  });

const showDrawer = computed({
  get() {
    return deepaChatDrawer.showDrawer.value;
  },
  set(value) {
    deepaChatDrawer.showDrawer.value = value;
  }
});

const { xs, smAndUp } = useDisplay();
watch(
  () => showDrawer.value,
  (newValue) => {
    if (newValue) {
      if (xs.value) {
        drawerWidth.value = window.innerWidth;
      } else {
        resetDrawerWidth();
      }
    } else {
      resetDrawerWidth();
    }
  }
);

const CHAT_ID = import.meta.env.VITE_DEEP_A_SUPPORT_CHAT_ID;

const down = (e: KeyboardEvent) => {
  if (e.key === 'h' && (e.metaKey || e.ctrlKey)) {
    e.preventDefault();
    showDrawer.value = !showDrawer.value;
  }
  if (e.key === 'Escape') {
    e.preventDefault();
    showDrawer.value = false;
  }
};
document.addEventListener('keydown', down);

const DRAWER_DEFAULT_WIDTH = 400;
const drawerWidth = ref(DRAWER_DEFAULT_WIDTH);

const isDefaultWidth = computed(
  () => drawerWidth.value === DRAWER_DEFAULT_WIDTH
);

function resetDrawerWidth() {
  drawerWidth.value = DRAWER_DEFAULT_WIDTH;
}

function onSetToggleWidth() {
  if (isDefaultWidth.value) {
    drawerWidth.value = window.innerWidth;
  } else {
    resetDrawerWidth();
  }
}
</script>
