import { useNode } from '@/composables/use-node';

export function useNodeComment() {
  const { getNodeById } = useNode();

  // UPDATE NODE COMMENT COUNT
  function updateNodeCommentCount(payload: { nodeId: string; count: number }) {
    const node = getNodeById(payload.nodeId);
    if (node) {
      node.commentCount = payload.count;
    }
  }

  return {
    updateNodeCommentCount
  };
}
