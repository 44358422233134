import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { deepAdminUsersMeLanguageAPI } from '@/api/deepadmin/users/users-me-language';

export const useDeepAdminUsersMeLanguageStore = defineStore(
  'deepAdminUsersMeLanguage',
  () => {
    const userLang = ref('');
    const fetchUserLanguagePending = ref(false);
    const storeUserLanguagePending = ref(false);

    async function fetchUserLanguage() {
      fetchUserLanguagePending.value = true;
      try {
        const res = await deepAdminUsersMeLanguageAPI.get();
        const resData = res?.data;
        if (resData.lang) {
          userLang.value = resData.lang.substr(0, 2);
        }
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchUserLanguagePending.value = false;
      }
    }

    async function storeUserLanguage(lang: string) {
      storeUserLanguagePending.value = true;
      try {
        const body = {
          lang
        };
        const res = await deepAdminUsersMeLanguageAPI.post(body);
        const resData = res?.data;
        if (resData.lang) {
          userLang.value = resData.lang.substr(0, 2);
        }
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        storeUserLanguagePending.value = false;
      }
    }

    return {
      // state
      userLang,
      fetchUserLanguagePending,
      storeUserLanguagePending,
      // actions
      fetchUserLanguage,
      storeUserLanguage
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepAdminUsersMeLanguageStore, import.meta.hot)
  );
}
