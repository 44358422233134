import { deepAdminApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { News } from '@/api/types/deepadmin/notifications/news';

export interface GetParams {
  all?: boolean;
}

export default class DeepAdminNotificationsNewsAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    params?: GetParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<News[]>> {
    return this.httpClient.get<News[]>(`notifications/news`, params, options);
  }
}

export const deepAdminNotificationsNewsAPI =
  new DeepAdminNotificationsNewsAPIService(deepAdminApi);
