import type { ValueOf } from '@/utils/deep/value_of.ts';

export const AXIOS_ERROR_CODES = {
  ERR_FR_TOO_MANY_REDIRECTS: 'ERR_FR_TOO_MANY_REDIRECTS',
  ERR_BAD_OPTION_VALUE: 'ERR_BAD_OPTION_VALUE',
  ERR_BAD_OPTION: 'ERR_BAD_OPTION',
  ERR_NETWORK: 'ERR_NETWORK',
  ERR_DEPRECATED: 'ERR_DEPRECATED',
  ERR_BAD_RESPONSE: 'ERR_BAD_RESPONSE',
  ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
  ERR_CANCELED: 'ERR_CANCELED',
  ECONNABORTED: 'ECONNABORTED',
  ETIMEDOUT: 'ETIMEDOUT'
} as const;

export type AxiosErrorCodes = ValueOf<typeof AXIOS_ERROR_CODES>;
