import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type {
  ContentData,
  ContentDataParams
} from '@/api/types/deepbox/globals';
import type { PathSegment } from '@/api/types/deepbox/path';
import helpers from '@/utils/helpers.ts';
import { deepBoxDeepBoxesBoxesFilesAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-files.ts';
import { useGlobalStore } from '@/stores/global/global.ts';
import { deepBoxDeepBoxesBoxesTrashAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-trash.ts';
import type { Node } from '@/api/types/deepbox/node.ts';
import { deepBoxDeepBoxesBoxesQueueAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-queue.ts';
import { useDeepBoxDeepBoxesBoxesInboxStore } from '@/stores/deepbox/deepboxes/boxes/inbox.ts';
import { useDeepBoxSearchStore } from '@/stores/deepbox/search.ts';
import { clone } from '@/utils/helpers/clone.ts';
import { sortStringToObject } from '@/utils/deep';
import orderBy from 'lodash/orderBy';

export type RootNodes = {
  queue: PathSegment | undefined;
  files: PathSegment | undefined;
  trash: PathSegment | undefined;
};

export type FetchNodesParams = {
  typeId: string;
  boxId: string;
  limit?: number;
  offset?: number;
  sortBy?: string[];
  refresh?: boolean;
};

export interface FetchNodesChildrenParams extends FetchNodesParams {
  nodeId: string;
}

const abortController = ref<AbortController>();

export const useDeepBoxDeepBoxesBoxesNodesStore = defineStore(
  'deepBoxDeepBoxesBoxesNodesStore',
  () => {
    const fetchNodesPending = ref(false);
    const data = ref<ContentData>();
    const params = ref<ContentDataParams>();
    const rootNodes = ref<RootNodes>({
      queue: undefined,
      files: undefined,
      trash: undefined
    });

    const getCurrentRootNode = computed(() => {
      if (!data.value || data.value?.path?.segments.length === 0) {
        return null;
      }
      return data.value?.path?.segments[data.value?.path?.segments.length - 1];
    });

    const getCurrentRootNodeId = computed(() => {
      if (!getCurrentRootNode.value) {
        return '';
      }
      return getCurrentRootNode.value?.nodeId;
    });

    function _handleSetData(contentData: ContentData, refresh?: boolean) {
      if (data.value && !refresh) {
        // clone the saved nodes and merge it with contentData
        const nodes: Node[] = clone(data.value.nodes);
        for (const node of contentData.nodes) {
          const nodeInDataIndex = nodes.findIndex(
            (n) => n.nodeId === node.nodeId
          );
          if (nodeInDataIndex > -1) {
            // replace the already present node with the one from contentData
            nodes.splice(nodeInDataIndex, 1, node);
            continue;
          }
          nodes.push(node);
        }

        data.value = {
          ...contentData,
          nodes
        };
      } else {
        data.value = contentData;
      }
    }

    function _handleNodeApiResponse(
      contentData: ContentData,
      contentDataParams: ContentDataParams,
      typeId: string,
      boxId: string,
      refresh?: boolean
    ) {
      // save the params
      params.value = contentDataParams;

      _handleSetData(contentData, refresh);

      const globalStore = useGlobalStore();
      globalStore.setBoxDetailsBreadCrumbs({
        segments: contentData?.path?.segments,
        typeId,
        boxId
      });
    }

    function _handleApiParams(payload: FetchNodesParams) {
      let offset = payload.offset ?? data.value?.nodes.length;
      if (payload.refresh && data.value) {
        // if `refresh` always get the initial count ( limit + offset ) in order to get this amount of items
        // this make the API call faster... the user can still scroll down to load more items
        offset = 0;
      }

      return helpers.getFetchParameters({
        ...payload,
        offset
      });
    }

    function abortOrCreateNewAbortController() {
      if (abortController.value) {
        console.warn('SHOULD ABORT ONGOING CALL');
        abortController.value.abort();
      }
      abortController.value = new AbortController();
    }

    function clearAbortController() {
      abortController.value = undefined;
    }

    const deepBoxDeepBoxesBoxesInboxStore =
      useDeepBoxDeepBoxesBoxesInboxStore();

    async function fetchBoxInbox(payload: FetchNodesParams) {
      abortOrCreateNewAbortController();

      fetchNodesPending.value = true;
      try {
        let paramsPayload = _handleApiParams(payload);

        if (deepBoxDeepBoxesBoxesInboxStore.hasFilterByBoxId(payload.boxId)) {
          paramsPayload = {
            ...paramsPayload,
            t: deepBoxDeepBoxesBoxesInboxStore.getFilterByTagsStringByBoxId(
              payload.boxId
            )
          };
        }

        const res = await deepBoxDeepBoxesBoxesQueueAPI.get(
          payload.typeId,
          payload.boxId,
          paramsPayload,
          {
            signal: abortController.value?.signal
          }
        );

        // save the params
        params.value = paramsPayload;

        _handleSetData(res.data, payload.refresh);

        // if there are nodes with analysisStatus = running, let's call the analyzeNode action.
        // this will update the relative analysisStatus property once the node has been analysed
        // TODO: this is tmp disabled unit the implementation of DEEPBOX-2822
        // res.data.nodes.forEach((node) => {
        //   if (node.analysisStatus === constants.ANALYSIS_STATUS_RUNNING) {
        //     dispatch('analyzeNode', node);
        //   }
        // });

        clearAbortController();
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchNodesPending.value = false;
      }
    }

    async function fetchBoxFiles(payload: FetchNodesParams) {
      abortOrCreateNewAbortController();

      fetchNodesPending.value = true;
      try {
        const paramsPayload = _handleApiParams(payload);

        const res = await deepBoxDeepBoxesBoxesFilesAPI.get(
          payload.typeId,
          payload.boxId,
          paramsPayload,
          {
            signal: abortController.value?.signal
          }
        );

        _handleNodeApiResponse(
          res.data,
          paramsPayload,
          payload.typeId,
          payload.boxId,
          payload.refresh
        );

        clearAbortController();
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchNodesPending.value = false;
      }
    }

    async function fetchBoxFileNodes(payload: FetchNodesChildrenParams) {
      abortOrCreateNewAbortController();

      fetchNodesPending.value = true;
      try {
        const paramsPayload = _handleApiParams(payload);

        const res = await deepBoxDeepBoxesBoxesFilesAPI.getById(
          payload.typeId,
          payload.boxId,
          payload.nodeId,
          paramsPayload,
          {
            signal: abortController.value?.signal
          }
        );

        _handleNodeApiResponse(
          res.data,
          paramsPayload,
          payload.typeId,
          payload.boxId,
          payload.refresh
        );

        clearAbortController();
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchNodesPending.value = false;
      }
    }

    async function fetchBoxTrash(payload: FetchNodesParams) {
      abortOrCreateNewAbortController();

      fetchNodesPending.value = true;
      try {
        const paramsPayload = _handleApiParams(payload);

        const res = await deepBoxDeepBoxesBoxesTrashAPI.get(
          payload.typeId,
          payload.boxId,
          paramsPayload,
          {
            signal: abortController.value?.signal
          }
        );

        _handleNodeApiResponse(
          res.data,
          paramsPayload,
          payload.typeId,
          payload.boxId,
          payload.refresh
        );

        clearAbortController();
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchNodesPending.value = false;
      }
    }

    async function fetchBoxTrashNodes(payload: FetchNodesChildrenParams) {
      abortOrCreateNewAbortController();

      fetchNodesPending.value = true;
      try {
        const paramsPayload = _handleApiParams(payload);

        const res = await deepBoxDeepBoxesBoxesTrashAPI.getById(
          payload.typeId,
          payload.boxId,
          payload.nodeId,
          paramsPayload,
          {
            signal: abortController.value?.signal
          }
        );

        _handleNodeApiResponse(
          res.data,
          paramsPayload,
          payload.typeId,
          payload.boxId,
          payload.refresh
        );

        clearAbortController();
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchNodesPending.value = false;
      }
    }

    function updateNodeState(payload: Node) {
      if (data.value) {
        const nodes = [...data.value.nodes];
        const nodeIndex = nodes?.findIndex(
          (node) => node.nodeId === payload.nodeId
        );
        if (nodeIndex !== -1) {
          const node = nodes[nodeIndex];
          nodes[nodeIndex] = { ...node, ...payload };
        }
        data.value.nodes = nodes;
      }
    }

    function removeNodeState(nodeId: string) {
      if (data.value) {
        const nodes = [...data.value.nodes];
        const idx = nodes.findIndex((node) => node.nodeId === nodeId);
        if (idx !== -1) {
          nodes.splice(idx, 1);
          data.value.nodes = nodes;
          data.value.size -= 1;
        }
      }
    }

    function updateRootNode(pathSegment: PathSegment) {
      if (data.value) {
        const node = { ...data.value };
        const nodePathSegmentIdx = node?.path?.segments.findIndex(
          (nodePathSegment) => nodePathSegment.nodeId === pathSegment.nodeId
        );
        if (nodePathSegmentIdx !== -1) {
          node.path.segments[nodePathSegmentIdx] = pathSegment;
          data.value = {
            ...node
          };
        }
      }
    }

    function addNodeState(node: Node) {
      // SET NODE
      if (data.value && data.value?.nodes) {
        data.value.nodes.push(node);
        if (data.value?.size) {
          data.value.size += 1;
        }

        if (!params.value) return;
        // Sort nodes
        const order = sortStringToObject(params.value?.order);

        if (order && order.sortBy && order.sortBy.length > 0) {
          const desc = order.sortDesc[0] === true ? 'desc' : 'asc';
          const sorter = helpers.getSorter(order);
          data.value.nodes = orderBy(data.value.nodes, sorter, desc);
          data.value.nodes = orderBy(data.value.nodes, ['type'], 'desc');
        }
      }
    }

    function getNodesByAnalysisStatus(analysisStatus: string) {
      if (!data.value) return [];
      return data.value.nodes.filter(
        (node) => node.analysisStatus === analysisStatus
      );
    }

    const selectedNodeIds = ref<string[]>([]);

    const deepBoxSearchStore = useDeepBoxSearchStore();
    const selectedNodesMap = computed(() => {
      const nodes = deepBoxSearchStore.searchResults?.items
        ? deepBoxSearchStore.searchResults?.items
        : data.value?.nodes;

      return nodes
        ?.filter((a) => selectedNodeIds.value.includes(a.nodeId))
        .reduce(
          (runningLookup, node) => ({
            ...runningLookup,
            [node.nodeId]: node
          }),
          {}
        );
    });

    const selectedNodes = computed((): Node[] => {
      if (
        selectedNodesMap.value &&
        Object.keys(selectedNodesMap.value).length > 0
      ) {
        return Object.values(selectedNodesMap.value);
      }
      return [];
    });

    return {
      // state
      fetchNodesPending,
      data,
      params,
      rootNodes,
      // getters
      getCurrentRootNode,
      getCurrentRootNodeId,

      // selection
      selectedNodeIds,
      selectedNodesMap,
      selectedNodes,

      // actions
      // inbox
      fetchBoxInbox,
      // documents
      fetchBoxFiles,
      fetchBoxFileNodes,
      // trash
      fetchBoxTrash,
      fetchBoxTrashNodes,

      // helpers
      updateNodeState,
      removeNodeState,
      updateRootNode,
      addNodeState,
      getNodesByAnalysisStatus
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxDeepBoxesBoxesNodesStore, import.meta.hot)
  );
}
