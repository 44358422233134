import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type {
  AnalyzeGetQueryParams,
  AnalyzePostQueryParams,
  AnalyzeResponse
} from '@/api/types/deepbox/analyze';

export default class DeepBoxNodesAnalyzeAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  post(
    nodeId: string,
    params?: AnalyzePostQueryParams
  ): Promise<HttpClientResponse<AnalyzeResponse>> {
    return this.httpClient.post<AnalyzeResponse>(
      `nodes/${nodeId}/analyze`,
      null,
      {
        params
      }
    );
  }

  get(
    nodeId: string,
    params?: AnalyzeGetQueryParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<AnalyzeResponse>> {
    return this.httpClient.get<AnalyzeResponse>(
      `nodes/${nodeId}/analyze`,
      params,
      options
    );
  }
}

export const deepBoxNodesAnalyzeAPI = new DeepBoxNodesAnalyzeAPIService(
  deepBoxApi
);
