export const SUPPORT_TYPES = {
  SUPPORT: 'support',
  FEEDBACK: 'feedback',
  SALES: 'sales'
} as const;

export type SupportTypes = (typeof SUPPORT_TYPES)[keyof typeof SUPPORT_TYPES];

export interface Support {
  type: SupportTypes | undefined;
  text: string;
  contact?: string;
  files?: string[];
}
