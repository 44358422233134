import i18n from '@/plugins/i18n';
import type { ValidationRuleBaseParams } from './types/validationRuleBaseParams';

export interface RuleNoCommaParams extends ValidationRuleBaseParams {}

/**
 * Rule validate no-comma
 * @param {any} v the given value to validate
 * @param {RuleNoCommaParams} params
 * @returns validate
 */
const validation = (v: string, params?: RuleNoCommaParams) => {
  return (
    !v.includes(',') || (params?.msg ?? i18n.global.t('validation.no_comma'))
  );
};

export default validation;
