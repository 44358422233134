<template>
  <v-form
    v-if="formModel"
    ref="formRef"
    v-model="isFormValid"
    :validate-on="isFormValid === undefined ? 'submit' : 'input'"
    @submit.prevent
  >
    <v-row>
      <v-col v-if="!hiddenFields.includes(FIELDS.NAME)" cols="12">
        <v-text-field
          v-model="formModel.name"
          :name="FIELDS.NAME"
          label="Name"
          hide-details="auto"
          autofocus
          :rules="[validationsRules.ruleRequired]"
        />
      </v-col>
      <v-col v-if="!hiddenFields.includes(FIELDS.VARIANT)" cols="12">
        <v-select
          v-model="formModel.boxVariant"
          :items="boxVariants"
          :name="FIELDS.VARIANT"
          label="Variant"
          hide-details="auto"
          clearable
        />
      </v-col>
      <v-col
        v-if="!hiddenFields.includes(FIELDS.ADD_OWNERS_AS_ORGANIZERS)"
        cols="12"
      >
        <v-checkbox
          v-model="formModel.addOwnersAsOrganizers"
          :name="FIELDS.ADD_OWNERS_AS_ORGANIZERS"
          label="add owners as organizers"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts" setup>
import { computed, type PropType, ref } from 'vue';
// types & constants
import type { BoxAdd, BoxUpdate } from '@/api/types/deepbox/box.ts';
import { FIELDS, type Fields } from '@/components/box/box-admin/const.ts';
// utilities
import validationsRules from '@/utils/validations/rules';

defineProps({
  organizationId: {
    type: String,
    default: null
  },
  hiddenFields: {
    type: Array as PropType<Fields[]>,
    default: null
  }
});

const formModel = defineModel({
  type: Object as PropType<BoxAdd | BoxUpdate>,
  default: undefined
});

const isFormValid = ref<boolean>();
const formRef = ref();

const boxVariants = computed(() => [
  {
    value: null,
    title: 'Simple Box'
  },
  {
    value: 'advanced',
    title: 'Advanced Box: costs extra 9.- per month'
  },
  {
    value: 'advanced-per-use',
    title: 'Advanced Box (pay per use)'
  }
]);

defineExpose({ formRef });
</script>
