import { useI18n } from 'vue-i18n';
import type {
  PaymentHistoryDestination,
  PaymentStatus
} from '@/api/types/deepo/payments-history.ts';

function getAddressLines(destination: PaymentHistoryDestination) {
  const entries: string[] = [];

  if (destination.name) {
    entries.push(destination.name);
  }

  const streetAndNumber = getStreetAndNumber(destination);
  if (streetAndNumber) {
    entries.push(getStreetAndNumber(destination));
  }
  const countryZipCity = getCountryZipCity(destination);
  if (countryZipCity) {
    entries.push(getCountryZipCity(destination));
  }

  return entries;
}

function getStreetAndNumber(destination: PaymentHistoryDestination) {
  if (destination.street) {
    if (destination.houseNumber) {
      return `${destination.street} ${destination.houseNumber}`;
    } else {
      return destination.street;
    }
  }
  return '';
}

function getCountryZipCity(destination: PaymentHistoryDestination) {
  if (destination.city) {
    if (destination.country && destination.zip) {
      return `${destination.country}-${destination.zip} ${destination.city}`;
    } else if (destination.zip) {
      return `${destination.zip} ${destination.city}`;
    } else {
      return destination.city;
    }
  }
  return '';
}

function getStatusColor(status: PaymentStatus) {
  switch (status) {
    case 'Transmitted':
      // empty, use default color
      return '';
    case 'Paid':
      return 'success';
    case 'Declined':
      return 'error';
  }
}

export function useDeepPayPaymentsHistory() {
  const { t } = useI18n();

  function getStatusText(status: PaymentStatus) {
    switch (status) {
      case 'Transmitted':
        return t('deeppay.payments_history.list.status.transmitted');
      case 'Paid':
        return t('deeppay.payments_history.list.status.paid');
      case 'Declined':
        return t('deeppay.payments_history.list.status.declined');
    }
  }

  return {
    getStatusText,
    getAddressLines,
    getStatusColor
  };
}
