<template>
  <div
    v-if="isBoxSelectionMenu"
    class="deepbox-box-name pr-1 text-truncate"
    :class="{
      'deepbox-box-name-tablet': device.isTablet,
      'flex-grow-1': !isDeadline,
      'deadline-box-name': isDeadline
    }"
  >
    {{ isShared ? box.deepBoxName : boxName }}
    <div
      v-if="showBoxNameSubtitle"
      class="ml-0 text-truncate text-medium-emphasis"
      :class="{
        'box-company': isShared || isRecent || isFavourite || isBoxSelectionMenu
      }"
    >
      {{ isShared ? boxName : box.deepBoxName }}
    </div>
  </div>
  <div
    v-else
    v-tooltip="tooltipData"
    class="deepbox-box-name pr-1 text-truncate"
    :class="{
      'deepbox-box-name-tablet': device.isTablet,
      'flex-grow-1': !isDeadline,
      'deadline-box-name': isDeadline
    }"
  >
    {{ isShared || isInbox || isDeadline ? boxDescription : boxName }}
    <div
      v-if="isShared || (isShared && !isDeadline) || showBoxNameSubtitle"
      class="ml-0 text-truncate text-medium-emphasis"
      :class="{
        'box-company':
          isShared || isRecent || isFavourite || isBoxSelectionMenu || isInbox
      }"
    >
      {{ box.boxName }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, type PropType } from 'vue';

// types & constants
import type { BoxEntry } from '@/api/types/deepbox/box';
import { DeviceKey } from '@/plugins/device-detector-js.ts';

const props = defineProps({
  isShared: {
    type: Boolean,
    default: false
  },
  isInbox: {
    type: Boolean,
    default: false
  },
  isRecent: {
    type: Boolean,
    default: false
  },
  isFavourite: {
    type: Boolean,
    default: false
  },
  isDeadline: {
    type: Boolean,
    default: false
  },
  box: {
    type: Object as PropType<BoxEntry>,
    default: () => ({})
  },
  isBoxSelectionMenu: {
    type: Boolean,
    default: false
  },
  showBoxNameSubtitle: {
    type: Boolean,
    default: false
  }
});

const device = inject(DeviceKey);

const boxName = computed(() => props.box?.boxName || props.box?.name);

const boxDescription = computed(() => {
  if (props.isShared) {
    return props.box?.company?.displayName;
  }
  if (
    props.isRecent ||
    props.isFavourite ||
    props.isBoxSelectionMenu ||
    props.isInbox ||
    props.isDeadline
  ) {
    if (props.isShared) {
      return props.box?.company?.displayName;
    }
    return props.isBoxSelectionMenu ? props.box.deepBoxName : props.box.boxName;
  }
  return '';
});

const tooltipData = computed(() => {
  let text = '';
  if (props.isShared) {
    text = `${boxDescription.value} (${props.box.boxName || props.box.deepBoxName})`;
  } else {
    text = props.box.boxName || props.box.deepBoxName;
  }
  return {
    text,
    location: 'top',
    offset: 3,
    openDelay: 300
  };
});
</script>

<style scoped lang="scss">
.deepbox-box-name {
  line-height: 1rem;
  display: block;
  max-width: 165px !important;
  text-align: left !important;
}

.deadline-box-name {
  margin-left: 10px;
  display: block;
  max-width: 130px !important;
}

.box-company {
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  font-size: 10px;
  line-height: 0.8rem;
  text-align: left !important;
}

.deepbox-box-name-tablet {
  max-width: 145px !important;
}
</style>
