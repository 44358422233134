import { deepAdminApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type { UserFull } from '@/api/types/deepadmin/users/user';

export default class DeepAdminUsersMeAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<UserFull>> {
    return this.httpClient.get<UserFull>('users/me', params, options);
  }
}

export const deepAdminUsersMeAPI = new DeepAdminUsersMeAPIService(deepAdminApi);
