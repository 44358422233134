<template>
  <DeepDialog
    v-bind="$attrs"
    v-model="showDialog"
    :max-width="500"
    :title="currentNewsTranslation?.title"
    card-title-cls="mx-2"
    toolbar-title-cls="black--text"
    :closable="false"
    @close="emit('close')"
    data-test-id="dialog-notifications-news"
  >
    <template #content>
      <v-card v-if="newsItem && currentNewsTranslation" flat>
        <DevTreeView
          :json="JSON.stringify({ newsItem, currentNewsTranslation })"
        />
        <v-window>
          <v-window-item class="pa-3">
            <!-- NEWS IMAGE-->
            <v-card
              v-if="currentNewsTranslation && currentNewsImage"
              color="transparent"
              flat
              rounded="xl"
            >
              <v-row
                v-if="currentNewsImage"
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-card-text class="text-center ma-0">
                  <v-img
                    v-if="currentNewsImage"
                    :src="currentNewsImage"
                    :lazy-src="currentNewsImage"
                    cover
                    class="rounded-lg"
                    max-width="600"
                    max-height="340"
                  />
                </v-card-text>
              </v-row>
            </v-card>
            <!-- /NEWS IMAGE-->
            <!-- NEWS CONTENT-->
            <v-card
              v-if="currentNewsTranslation"
              class="mt-7 p2-4"
              color="transparent"
              flat
            >
              <v-row>
                <v-card-title v-if="currentNewsTranslation.lead_text">
                  {{ currentNewsTranslation.lead_text }}
                </v-card-title>
                <v-card-text v-if="currentNewsTranslation.text">
                  <div v-html="getSanitizedHtml(currentNewsTranslation.text)" />
                </v-card-text>
              </v-row>
            </v-card>
            <!-- /NEWS CONTENT-->
          </v-window-item>
        </v-window>
        <v-card-actions class="justify-center">
          <v-btn variant="text" color="primary" @click="showDialog = false">
            {{ t('dialogs.close') }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="
              currentNewsTranslation.secondary_cta_button_text &&
              currentNewsTranslation.secondary_cta_button
            "
            variant="outlined"
            color="primary"
            :href="currentNewsTranslation.secondary_cta_button"
            target="_blank"
          >
            {{ currentNewsTranslation.secondary_cta_button_text }}
          </v-btn>
          <v-btn
            v-if="
              currentNewsTranslation.primary_cta_button_text &&
              currentNewsTranslation.primary_cta_button
            "
            color="primary"
            :href="currentNewsTranslation.primary_cta_button"
            target="_blank"
          >
            {{ currentNewsTranslation.primary_cta_button_text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
import { computed, type PropType, ref, watch } from 'vue';
import DevTreeView from '@/components/dev/DevTreeView.vue';
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import type { News } from '@/api/types/deepadmin/notifications/news';
import { useNotificationsNews } from '@/components/notifications/use-notifications-news';
import { getSanitizedHtml } from '@/utils/helpers/html-sanitize.ts';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  newsItem: {
    type: Object as PropType<News>,
    default: () => ({})
  }
});

const emit = defineEmits(['close']);

const { t } = useI18n();

const showDialog = ref(false);
const { getCurrentTranslationByItem, getCurrentNewsImage } =
  useNotificationsNews();

const currentNewsTranslation = computed(() =>
  getCurrentTranslationByItem(props.newsItem)
);

const currentNewsImage = computed(() => {
  return getCurrentNewsImage(props.newsItem);
});

watch(
  () => showDialog.value,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      emit('close');
    }
  },
  { immediate: true }
);

watch(
  () => props.newsItem,
  (newValue) => {
    if (newValue) {
      if (currentNewsTranslation.value) {
        showDialog.value = true;
      }
    }
  },
  { immediate: true }
);
</script>
