import { deepBoxApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type {
  ContentData,
  ContentDataParams
} from '@/api/types/deepbox/globals';

export default class DeepBoxDeepBoxesBoxesTrashAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: ContentDataParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<ContentData>> {
    return this.httpClient.get<ContentData>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/trash`,
      params,
      options
    );
  }

  deleteById(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: Record<string, unknown>
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.delete<never>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/trash`,
      params
    );
  }

  getById(
    deepBoxNodeId: string,
    boxNodeId: string,
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<ContentData>> {
    return this.httpClient.get<ContentData>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/trash/${nodeId}`,
      params,
      options
    );
  }
}

export const deepBoxDeepBoxesBoxesTrashAPI =
  new DeepBoxDeepBoxesBoxesTrashAPIService(deepBoxApi);
