import * as apis from '@/api/interceptors/index';
import { AxiosHttpClient } from '@/api/http-client';

const setApisAcceptLanguageHeader = (locale) => {
  Object.keys(apis).forEach((api) => {
    if (apis[api] instanceof AxiosHttpClient) {
      apis[api].setHeaderAcceptLanguage(locale);
      return;
    }

    apis[api].defaults.headers.common['Accept-Language'] = locale;
  });
};

export { setApisAcceptLanguageHeader };
