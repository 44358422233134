import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import type { PathSegment } from '@/api/types/deepbox/path';
import { deepBoxDeepBoxesBoxesFilesPathAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-files-path';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes.ts';
import type { FileTree } from '@/types/file-tree.ts';

export const useDeepBoxDeepBoxesBoxesFilesTreeStore = defineStore(
  'deepBoxDeepBoxesBoxesFilesTreeStore',
  () => {
    const fetchFilesTreePending = ref(false);
    const fetchFilesTreeChildrenPending = ref(false);
    const filesTree = ref<FileTree[]>([]);

    async function fetchFilesTree(payload: { typeId: string; boxId: string }) {
      fetchFilesTreePending.value = true;
      try {
        const res = await deepBoxDeepBoxesBoxesFilesPathAPI.get(
          payload.typeId,
          payload.boxId
        );

        const deepBoxDeepBoxesBoxesNodesStore =
          useDeepBoxDeepBoxesBoxesNodesStore();

        updateTreeReferences(
          res.data.children,
          filesTree.value,
          // add 'files' node id as initial parentId
          deepBoxDeepBoxesBoxesNodesStore.rootNodes?.files?.nodeId
        );

        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchFilesTreePending.value = false;
      }
    }

    async function fetchFilesTreeChildren(payload: {
      typeId: string;
      boxId: string;
      nodeId: string;
      parent?: FileTree;
    }) {
      fetchFilesTreeChildrenPending.value = true;
      try {
        const res = await deepBoxDeepBoxesBoxesFilesPathAPI.getByNodeId(
          payload.typeId,
          payload.boxId,
          payload.nodeId
        );

        if (payload?.parent && res.data?.children) {
          if (!payload.parent?.children) {
            payload.parent.children = [];
          }

          const fetchedChildren = res.data.children;

          updateTreeReferences(
            fetchedChildren,
            payload.parent.children,
            payload.parent.nodeId
          );
        }

        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        fetchFilesTreeChildrenPending.value = false;
      }
    }

    function updateTreeReferences(
      fetchedNodes: PathSegment[],
      currentReferenceNodes: FileTree[],
      parentNodeId?: string
    ) {
      // used to sort the children like the fetched ones
      const sortIndexes: {
        [nodeId: string]: number;
      } = {};

      fetchedNodes.forEach((fetchedNode, index) => {
        const referenceNode = currentReferenceNodes.find(
          (c) => c.nodeId === fetchedNode.nodeId
        );
        sortIndexes[fetchedNode.nodeId] = index;
        if (parentNodeId && !fetchedNode.parentNodeId) {
          fetchedNode.parentNodeId = parentNodeId;
        }
        if (referenceNode) {
          // update reference node, but do not overwrite the children because
          // missing in the fetched data
          for (const key in fetchedNode) {
            if (key !== 'children') {
              referenceNode[key] = fetchedNode[key];
            }
          }
        } else {
          // the fetched child is not present in the reference yet, so add it
          fetchedNode.children = [];
          currentReferenceNodes.push(fetchedNode);
        }
      });

      // sort children like the fetched ones
      currentReferenceNodes.sort((a, b) => {
        return sortIndexes[a.nodeId] - sortIndexes[b.nodeId];
      });

      // remove children from the reference which are not present in the
      // fetched data
      const childIndexesToRemove = [];
      currentReferenceNodes.forEach((child, referenceIndex) => {
        const findNode = fetchedNodes.find((c) => c.nodeId === child.nodeId);
        if (!findNode) {
          childIndexesToRemove.push(referenceIndex);
        }
      });
      // reverse the indexes array, because on splice the size of the array
      // changes
      childIndexesToRemove.reverse();
      childIndexesToRemove.forEach((index) => {
        currentReferenceNodes.splice(index, 1);
      });
    }

    function getFileTreeNodeByNodeId(nodeId: string, items: FileTree[]) {
      // https://stackoverflow.com/questions/68997102/find-item-by-id-in-treeview-node-list
      for (const n of items) {
        if (n.nodeId === nodeId) return n;
      }
      for (const n of items) {
        const res = getFileTreeNodeByNodeId(nodeId, n.children);
        if (res) return res;
      }
    }

    return {
      // state
      fetchFilesTreePending,
      fetchFilesTreeChildrenPending,
      filesTree,
      // getters
      getFileTreeNodeByNodeId,
      // actions
      fetchFilesTree,
      fetchFilesTreeChildren
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxDeepBoxesBoxesFilesTreeStore, import.meta.hot)
  );
}
