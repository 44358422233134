<template>
  <v-snackbar
    v-if="showPrompt"
    v-model="showPrompt"
    color="primary"
    :content-class="{ 'bg-white': !current.dark, 'bg-black': current.dark }"
    vertical
    location="top"
    max-width="400"
    variant="outlined"
    :timeout="-1"
  >
    {{ t('service_worker.text_new_version_available') }} <br />
    {{ t('service_worker.text_reload_recommended') }}
    <template #actions>
      <VBtnTertiary @click="onClickReloadLater">
        {{ t('service_worker.btn_reload_later') }}
      </VBtnTertiary>
      <VBtnPrimary v-if="showPrompt" class="ml-4" @click="onClickRefresh">
        {{ t('service_worker.btn_reload_now') }}
      </VBtnPrimary>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useTheme } from 'vuetify';
import axios from 'axios';
import { useWatchExternalResource } from '@/composables/use-watch-external-resource';
import { useI18n } from 'vue-i18n';

interface ReleaseResponse {
  RELEASE_TAG: string;
  RELEASE_DATETIME: string;
  RELEASE_COMMIT_ID: string;
  RELEASE_CI_PIPELINE_ID: string;
  ENV: string;
}

const { current } = useTheme();
const route = useRoute();
const { t } = useI18n();

const { hasChanged, stop } = useWatchExternalResource(fetchReleaseVersion);
const showPrompt = ref(false);

watch(hasChanged, (changed) => {
  if (changed) {
    showPrompt.value = true;
  }
});

watch(
  () => route.name,
  (newValue) => {
    if (newValue && hasChanged.value) {
      window.location.reload();
    }
  },
  { immediate: true }
);

async function fetchReleaseVersion() {
  return axios
    .get<ReleaseResponse>('/release.json', {
      params: {
        // Add a random query string for prevent caching
        r: (Math.random() + 1).toString(36).substring(7)
      }
    })
    .then((response) => {
      return response.data?.RELEASE_TAG;
    });
}

function onClickReloadLater() {
  showPrompt.value = false;
  stop();
}

function onClickRefresh() {
  window.location.reload();
}
</script>
