import { deepOApi } from '@/api/interceptors';
import type {
  HttpClientResponse,
  HttpClient,
  HttpClientGetOptions
} from '@/api/http-client';
import type {
  CreatePaymentHistory,
  GetPaymentHistoryResponse
} from '@/api/types/deepo/payments-history';
import type { DeepOQueryParams } from '@/api/types/deepo/query-params.ts';

export default class DeepOPaymentsHistoryAPIService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  get(
    id: string,
    params?: DeepOQueryParams,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<GetPaymentHistoryResponse>> {
    return this.httpClient.get<GetPaymentHistoryResponse>(
      `/payments-history/v1/documents/${id}/payments`,
      params,
      options
    );
  }

  post(
    id: string,
    body?: CreatePaymentHistory
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.post<never>(
      `/payments-history/v1/documents/${id}/payments`,
      body
    );
  }

  getByDestinationAddress(
    params?: DeepOQueryParams & Record<string, string>,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<GetPaymentHistoryResponse>> {
    return this.httpClient.get<GetPaymentHistoryResponse>(
      `/payments-history/v1/payments`,
      params,
      options
    );
  }
}

export const deepOPaymentsHistoryAPIService =
  new DeepOPaymentsHistoryAPIService(deepOApi);
