import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts';
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts';
import { DeepBoxError } from '@/models';
import { useKeycloak } from '@/composables/auth/use-keycloak.ts';
import { useLocalStorage } from '@vueuse/core';
import { deepBoxBoxesInfoAPI } from '@/api/deepbox/boxes/boxes-info.js';
import { useGlobalStore } from '@/stores/global/global.ts';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

function getLastOrgUsedOrUndefined() {
  const { user } = useKeycloak();
  const lastOrgId = useLocalStorage(`last_org_${user.value?.sub}`, '');

  const deepAdminUsersMeStore = useDeepAdminUsersMeStore();

  return deepAdminUsersMeStore.getUserSelectableOrganizations.find(
    (org) => org.group_id === lastOrgId.value
  );
}

async function caseRouteDoNotHasOrganizationIdAndNoLastOrgIfFound({ next }) {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
  const globalStore = useGlobalStore();
  const { user } = useKeycloak();
  const lastOrgId = useLocalStorage(`last_org_${user.value?.sub}`, '');

  const lastUserOrg = getLastOrgUsedOrUndefined();
  if (lastUserOrg) {
    deepAdminUsersMeStore.selectedOrganization = { ...lastUserOrg };
  }

  let orgGroupId = deepAdminUsersMeStore.selectedOrganizationId;
  if (!orgGroupId) {
    // set 1. org on the users list if there is not selected Organization
    orgGroupId =
      deepAdminUsersMeStore.getUserSelectableOrganizations[0].group_id;
    deepAdminUsersMeStore.selectedOrganization = {
      ...deepAdminUsersMeStore.getUserSelectableOrganizations[0]
    };
  }
  if (orgGroupId) {
    if (orgGroupId) {
      lastOrgId.value = orgGroupId;
    }
    next({
      name: 'organization',
      params: {
        organization: orgGroupId
      },
      replace: true
    });
  } else {
    globalStore.fetchInitialPending = false;
    next();
  }
}

async function caseDashboardEmptyParamRoute({ next }) {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore();

  if (deepAdminUsersMeStore.organizations.length === 0) {
    await deepAdminUsersMeStore.fetchUserOrganizations();
  }

  const lastUserOrg = getLastOrgUsedOrUndefined();
  if (lastUserOrg) {
    deepAdminUsersMeStore.selectedOrganization = { ...lastUserOrg };
  }

  // if the user is authenticated and navigate to the "organisation" path without organisation param
  // be sure that the selected organisation is used and the organisation id is on the url
  const selectedOrganizationGroupId =
    deepAdminUsersMeStore.selectedOrganizationId;

  const selectedOrganizationGroupIdExists =
    deepAdminUsersMeStore.getUserSelectableOrganizations.findIndex(
      (org) => org.group_id === selectedOrganizationGroupId
    );

  const orgId =
    selectedOrganizationGroupIdExists !== -1
      ? selectedOrganizationGroupId
      : deepAdminUsersMeStore.getUserSelectableOrganizations[0].group_id;
  if (orgId) {
    next({
      name: 'organization',
      params: {
        organization: orgId
      }
    });
  } else next();
}

function caseUserDoNotHaveAnyOrganizations() {
  window.open(import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL, '_self');
  return;
}

async function caseRouteHasOrganizationId({ next, to }) {
  const { user } = useKeycloak();
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
  const globalStore = useGlobalStore();

  const lastOrgId = useLocalStorage(`last_org_${user.value?.sub}`, '');

  const toParamOrganization = to.params?.organization;

  // Find org with path param
  const org = deepAdminUsersMeStore.getUserSelectableOrganizations.find(
    (o) => o.group_id === toParamOrganization
  );

  // If org is part of user organizations, and different as already selected, switch
  if (org) {
    if (toParamOrganization) {
      lastOrgId.value = toParamOrganization;
    }
    if (org.group_id !== deepAdminUsersMeStore.selectedOrganizationId) {
      deepAdminUsersMeStore.selectedOrganization = { ...org };
    }
    globalStore.fetchInitialPending = false;
    next();
    return;
  } else {
    // If path param is different as selected org, redirect
    if (to.params?.box) {
      try {
        const res = await deepBoxBoxesInfoAPI.get(to.params?.box);
        if (res.data?.accessGrantedBy.length > 0) {
          if (to.name) {
            next({
              name: to.name,
              params: {
                ...to.params,
                organization: res.data.accessGrantedBy[0].companyId
              }
            });
          }
          return;
        }
        next();
        return;
      } catch (e) {
        const errorCode = e.response.status;
        if ([403, 404].includes(errorCode)) {
          DeepErrorHandlerService.error(
            new DeepBoxError('error.no_box_access')
          );
          next();
          return;
        } else {
          DeepErrorHandlerService.error(
            new DeepBoxError('error.error_occurred')
          );
          next();
          return;
        }
      } finally {
        globalStore.fetchInitialPending = false;
      }
    }

    console.warn(`Organization with id: ${toParamOrganization}: not found`);
    DeepErrorHandlerService.error(
      new DeepBoxError('error.organization_not_found', {
        statusCode: 400
      })
    );
    globalStore.fetchInitialPending = false;
    next();
    return;
  }
}

export default async function ({
  next,
  to
}: {
  next: NavigationGuardNext;
  to: RouteLocationNormalized;
}) {
  const { user, isAuthenticated } = useKeycloak();
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
  const globalStore = useGlobalStore();

  if (!isAuthenticated.value || deepAdminUsersMeStore.selectedOrganizationId) {
    console.log('Middleware (needs-organization-context): Case 01');
    globalStore.fetchInitialPending = false;
    next();
    return;
  }

  const toParamOrganization = to.params?.organization;

  const lastOrgId = useLocalStorage(`last_org_${user.value?.sub}`, '');

  globalStore.fetchInitialPending = true;

  const isDashboardEmptyParamRoute =
    to.name === 'organization' && !toParamOrganization;

  if (isDashboardEmptyParamRoute) {
    console.log('Middleware (needs-organization-context): Case 02');
    return caseDashboardEmptyParamRoute({ next });
  }

  if (deepAdminUsersMeStore.organizations.length === 0) {
    await deepAdminUsersMeStore.fetchUserOrganizations();
  }

  if (deepAdminUsersMeStore.getUserSelectableOrganizations.length === 0) {
    console.log('Middleware (needs-organization-context): Case 03');
    return caseUserDoNotHaveAnyOrganizations();
  }

  // Get last org used
  const lastUserOrg = getLastOrgUsedOrUndefined();

  // Check if last org is part of user's organizations, if not remove key and set lastOrgId to null
  if (!lastUserOrg) {
    lastOrgId.value = undefined;
  }

  if (toParamOrganization) {
    console.log('Middleware (needs-organization-context): Case 04');
    return caseRouteHasOrganizationId({ next, to });
  } else if (!lastOrgId.value) {
    console.log('Middleware (needs-organization-context): Case 05');
    return caseRouteDoNotHasOrganizationIdAndNoLastOrgIfFound({ next });
  } else {
    console.log('Middleware (needs-organization-context): Case 06');
    if (lastUserOrg) {
      deepAdminUsersMeStore.selectedOrganization = { ...lastUserOrg };
    }
    globalStore.fetchInitialPending = false;
    if (to.name === 'organization') {
      next({
        name: 'organization',
        params: {
          organization: lastOrgId.value
        },
        replace: true
      });
    } else next();
  }
}
