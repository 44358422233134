<template>
  <DeepDialog v-model="dialog" max-width="500">
    <template #toolbar>
      <v-toolbar flat dense :color="color" class="text-white">
        <v-icon color="white">fas fa-circle-exclamation</v-icon>
        <v-toolbar-title class="pl-2">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon="fa fa-close"
          size="small"
          color="white"
          class="mr-0"
          @click="cancel"
        >
        </v-btn>
      </v-toolbar>
    </template>
    <!-- Content -->
    <template #content>
      <span v-html="getSanitizedHtml(message)"></span>
      <v-expansion-panels v-if="items.length > 0" flat>
        <v-expansion-panel>
          <v-expansion-panel-title class="px-0">
            <div class="panel-header">{{ t('dialogs.details') }}</div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="px-0">
            <v-data-table
              :headers="headers"
              :items="formattedItems"
              hide-default-footer
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-checkbox
        v-if="options.type === 'checkbox'"
        v-model="checkbox"
        :label="options.checkbox.label"
        name="field-security-agree"
      />
    </template>
    <!-- /Content -->
    <!-- Actions -->
    <template #actions>
      <v-spacer />
      <VBtnSecondary name="btn-cancel" @click="cancel">
        {{ t('actions.cancel') }}
      </VBtnSecondary>
      <VBtnPrimary
        name="btn-agree"
        :color="color"
        :disabled="!checkbox && options.type === 'checkbox'"
        @click="agree"
      >
        {{ agreeButtonText }}
      </VBtnPrimary>
    </template>
    <!-- /Actions -->
  </DeepDialog>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { useI18n } from 'vue-i18n';
import { getSanitizedHtml } from '@/utils/helpers/html-sanitize.ts';

const { t } = useI18n();
const dialog = ref(false);
const checkbox = ref(false);
const options = ref({});
const resolve = ref(null);
const reject = ref(null);

const items = computed(() => options.value?.logEntries || []);

const headers = computed(() => {
  const headers = [
    {
      text: t('services.download.headers.file'),
      value: 'filename',
      sortable: false
    },
    {
      text: t('services.download.headers.path'),
      value: 'context',
      sortable: false
    },
    {
      text: t('services.download.headers.virus_signature'),
      value: 'info',
      sortable: false
    }
  ];
  if (items.value.some((item) => item.info !== null)) {
    return headers;
  }
  return headers.filter((header) => header.value !== 'info');
});

const formattedItems = computed(() => {
  items.value.forEach((item) => {
    item.filename = getFilename(item.context);
  });
  return items.value;
});

const title = computed(() => options.value?.title?.text || '');
const message = computed(() => options.value?.message || '');

const agreeButtonText = computed(
  () => options.value?.buttons?.agree?.text || ''
);

const color = computed(() => options.value?.color || 'default');

watch(
  () => dialog.value,
  (newValue) => {
    if (!newValue) {
      checkbox.value = false;
    }
  },
  { immediate: true }
);

function open(dialogOption) {
  options.value = dialogOption;
  dialog.value = true;
  return new Promise((resolvePromise, rejectPromise) => {
    resolve.value = resolvePromise;
    reject.value = rejectPromise;
  });
}

function agree() {
  resolve.value(true);
  dialog.value = false;
}

function cancel() {
  resolve.value(false);
  dialog.value = false;
}

function getFilename(context) {
  const pathArray = context.split('/');
  const lastIndex = pathArray.length - 1;
  return pathArray[lastIndex];
}

defineExpose({ open });
</script>

<style scoped lang="scss">
:deep(.v-expansion-panel-content__wrap) {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.panel-header {
  color: rgba(0, 0, 0, 0.6);
}
</style>
