import DOMPurify from 'dompurify';

export function getSanitizedHtml(content: string) {
  // HOOK to add target="blank" and rel attribute to all links
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DOMPurify.addHook('afterSanitizeAttributes', function (node: any) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
      node.setAttribute('rel', 'noopener noreferrer');
    }
    // set non-HTML/MathML links to xlink:show=new
    if (
      !node.hasAttribute('target') &&
      (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
    ) {
      node.setAttribute('xlink:show', 'new');
    }
    return node;
  });

  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [
      'b',
      'i',
      'em',
      'strong',
      // 'a',
      'p',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'li',
      'ol',
      'ul',
      'blockquote',
      'div'
    ]
    // ALLOWED_ATTR: ['href'],
    // ALLOW_UNKNOWN_PROTOCOLS: true,
    // ADD_ATTR: ['target']
  });
}
