<template>
  <v-menu
    v-bind="$attrs"
    ref="menuRef"
    :close-on-content-click="false"
    :location="location"
    :min-width="props.minWidth"
    :max-width="props.maxWidth"
  >
    <!--
      Dynamically inherit slots from parent
      & make all slots from component reusable from parent
    -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <template v-if="props.isSubMenu" #activator="{ props: ActivatorProps }">
      <v-list-item
        v-bind="ActivatorProps"
        :data-test-id="dataTestId"
        :density="props.density"
        :title="props[props.titleAccessor]"
        slim
      >
        <template v-if="props[props.iconAccessor]" #prepend>
          <v-icon class="mr-2" :color="props.color">
            {{ props[props.iconAccessor] }}
          </v-icon>
        </template>
        <template #append>
          <v-icon class="pa-0 ma-0" size="small">far fa-chevron-right</v-icon>
        </template>
      </v-list-item>
    </template>
    <v-list
      :density="props.density"
      :min-width="props.minWidth"
      :max-width="props.maxWidth"
    >
      <template
        v-for="(item, index) in props.items"
        :key="`core-item-${index}`"
      >
        <v-divider v-if="item.divider" :key="index" :class="item.space" />
        <v-spacer v-else-if="item.spacer" :class="item.space" />
        <CoreMenuNested
          v-else-if="item.children"
          :items="item.children"
          :title="item.title"
          :icon="item.icon"
          :color="item.color"
          :is-sub-menu="true"
          :location="xs ? 'bottom' : 'left'"
          :max-width="props.maxWidth"
          :density="props.density"
          :open-on-hover="canHoverMediaQuery"
          :open-on-focus="false"
          v-bind="$attrs"
          submenu
          @click:item="emitClickEvent"
        />
        <v-list-item
          v-else
          :density="props.density"
          :data-test-id="item.dataTestId"
          v-bind="item"
          :title="item[titleAccessor]"
          slim
          @click="emitClickEvent(item)"
        >
          <template #prepend v-if="item.icon">
            <v-icon class="mr-2" :color="item[props.iconColorAccessor]">
              {{ item[props.iconAccessor] }}
            </v-icon>
          </template>
          <template #append v-if="item.action">
            <v-icon
              class="pa-0 ma-0"
              :color="item.action[props.iconColorAccessor]"
            >
              {{ item.action[props.iconAccessor] }}
            </v-icon>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { ref } from 'vue';
import type { VMenu } from 'vuetify/components';
import type { MenuNestedItem } from '@/types/menu-item';
import { useHover } from '@/composables/use-hover.ts';
import { useDisplay } from 'vuetify';

const props = defineProps({
  items: {
    type: Array as PropType<MenuNestedItem[]>,
    required: true,
    default: () => []
  },
  titleAccessor: {
    type: String,
    default: 'title'
  },
  title: {
    type: String,
    default: undefined
  },
  color: {
    type: String,
    default: undefined
  },
  icon: {
    type: String,
    default: undefined
  },
  iconAccessor: {
    type: String,
    default: 'icon'
  },
  iconColorAccessor: {
    type: String,
    default: 'color'
  },
  density: {
    type: String,
    default: 'default'
  },
  isSubMenu: {
    type: Boolean,
    default: false
  },
  minWidth: {
    type: [String, Number] as PropType<string | number>,
    default: 200
  },
  maxWidth: {
    type: [String, Number] as PropType<string | number>,
    default: 300
  },
  dataTestId: {
    type: String,
    default: undefined
  },
  location: {
    type: String,
    default: 'bottom'
  }
});

const emit = defineEmits(['click:item']);

const { xs } = useDisplay();

const menuRef = ref<typeof VMenu | null>(null);

const { canHoverMediaQuery } = useHover();

function emitClickEvent(item: MenuNestedItem) {
  emit('click:item', item);
}

const close = () => {
  //  TODO(VUE-3-MIGRATION): V-Menu fix the progr. close
  // menuRef.value?.save();
};

defineExpose({ close });
</script>
