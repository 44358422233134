<template>
  <a
    v-bind="$attrs"
    class="app-link-external"
    :style="{
      color: color
    }"
    :target="target"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts" setup>
defineProps({
  color: {
    type: String,
    default: '#1867c0'
  },
  target: {
    type: String,
    default: '_blank'
  }
});
</script>

<style lang="scss" scoped>
.app-link-external {
  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }
}
</style>
