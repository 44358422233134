<template>
  <v-list-item
    v-bind="$attrs"
    :color="color"
    density="comfortable"
    :title="title"
    :subtitle="subtitle"
    rounded="0"
  >
    <template #prepend>
      <slot v-if="!settingsStore.navDrawerMini" name="icon-prepend">
        <span class="icon-prepend">
          <v-icon size="x-small">
            {{ iconPrepend }}
          </v-icon>
        </span>
      </slot>

      <slot v-if="icon || $slots.icon || $slots['icon-content']" name="icon">
        <CoreTooltip :location="settingsStore.navDrawerMini ? 'right' : 'top'">
          <template #activator="activator">
            <slot
              v-if="$slots['icon-content'] || icon"
              name="icon-content"
              :activator="activator"
            >
              <v-icon
                v-bind="activator.props"
                :color="iconColor"
                @dblclick="emit('icon:dblclick')"
              >
                {{ icon }}
              </v-icon>
            </slot>
          </template>
          <span class="text-caption">{{ title }}</span>
        </CoreTooltip>
      </slot>
    </template>

    <!--
  Dynamically inherit slots from parent
  & make all slots from component reusable from parent
-->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <slot></slot>
  </v-list-item>
</template>

<script lang="ts" setup>
import { useSettingsStore } from '@/stores/settings/settings';
import CoreTooltip from '@/components/core/CoreTooltip.vue';

defineProps({
  title: {
    type: String,
    default: undefined
  },
  titleItemCls: {
    type: String,
    default: undefined
  },
  subtitle: {
    type: String,
    default: undefined
  },
  subtitleItemCls: {
    type: String,
    default: undefined
  },
  icon: {
    type: String,
    default: undefined
  },
  iconPrepend: {
    type: String,
    default: undefined
  },
  iconColor: {
    type: String,
    default: undefined
  },
  color: {
    type: String,
    default: 'primary'
  },
  rail: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['icon:dblclick']);

const settingsStore = useSettingsStore();
</script>

<style scoped>
:deep(.v-list-item__prepend) > .icon-prepend {
  margin-inline-end: 12px !important;
  margin-inline-start: 0 !important;
}
</style>
