import {
  isAuthenticated,
  bearer,
  token,
  updateToken
} from '@/composables/auth/use-keycloak';

const setInterceptorHeaderAuthorization = async (headers) => {
  if (isAuthenticated.value) {
    await updateToken();

    if (token.value) {
      headers.Authorization = bearer.value;
    }
    return Promise.resolve(headers);
  }
  return Promise.resolve();
};

export { setInterceptorHeaderAuthorization };
