// Matomo
import VueMatomo from 'vue-matomo';
import initializeRouter from '@/router';
import type { App } from 'vue';

export const matomo = (app: App) => {
  console.log('installing matomo plugin');
  if (import.meta.env.VITE_MATOMO_ENABLED === 'true') {
    console.log('Matomo is enabled');
    app.use(VueMatomo, {
      router: initializeRouter(),
      host: import.meta.env.VITE_MATOMO_HOST,
      siteId: import.meta.env.VITE_MATOMO_SITE_ID
    });
  }
};

export default matomo;
