import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import type { AdminBox, AdminDeepBox } from '@/api/types/deepbox/admin';
import type { BoxAdd } from '@/api/types/deepbox/box';
import { deepBoxAdminDeepBoxesAPI } from '@/api/deepbox/admin/admin-deepboxes';
import { deepBoxAdminDeepBoxesBoxesAPI } from '@/api/deepbox/admin/admin-deepboxes-boxes';

interface CreateNewBox extends BoxAdd {
  deepBoxNodeId?: string;
}

export const useDeepBoxAdminStore = defineStore('deepBoxAdmin', () => {
  const adminBox = ref<AdminBox | undefined>(undefined);
  const fetchAdminBoxByIdPending = ref(false);
  const organizationTypes = ref<AdminDeepBox[]>([]);
  const fetchOrganizationTypesPending = ref(false);
  const createNewDeepBoxPending = ref(false);
  const createNewBoxPending = ref(false);

  async function fetchAdminBoxById(payload: { typeId: string; boxId: string }) {
    fetchAdminBoxByIdPending.value = true;
    try {
      const res = await deepBoxAdminDeepBoxesBoxesAPI.getById(
        payload.typeId,
        payload.boxId
      );
      adminBox.value = res?.data;
      return Promise.resolve(res);
    } catch (error) {
      adminBox.value = undefined;
      return Promise.reject(error);
    } finally {
      fetchAdminBoxByIdPending.value = false;
    }
  }

  async function fetchOrganizationTypes(organizationId: string) {
    fetchOrganizationTypesPending.value = true;
    try {
      const params = {
        filterCompanyId: organizationId
      };
      const res = await deepBoxAdminDeepBoxesAPI.get({
        params
      });
      organizationTypes.value = [...res.data.deepBoxes];
      return Promise.resolve(res);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    } finally {
      fetchOrganizationTypesPending.value = false;
    }
  }

  async function createNewDeepBox({
    name,
    organizationId,
    createInitialBox = false
  }: {
    name: string;
    organizationId: string;
    createInitialBox: boolean;
  }) {
    createNewDeepBoxPending.value = true;
    try {
      const params = {
        name,
        companyId: organizationId,
        createInitialBox
      };
      const res = await deepBoxAdminDeepBoxesAPI.post(params);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      createNewDeepBoxPending.value = false;
    }
  }

  async function createNewBox(data: CreateNewBox) {
    const deepBoxNodeId = data?.deepBoxNodeId as string;
    delete data.deepBoxNodeId;
    createNewBoxPending.value = true;
    try {
      const res = await deepBoxAdminDeepBoxesBoxesAPI.post(deepBoxNodeId, data);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      createNewBoxPending.value = false;
    }
  }

  return {
    // state
    adminBox,
    fetchAdminBoxByIdPending,
    organizationTypes,
    fetchOrganizationTypesPending,
    createNewDeepBoxPending,
    createNewBoxPending,
    // actions
    fetchAdminBoxById,
    fetchOrganizationTypes,
    createNewDeepBox,
    createNewBox
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxAdminStore, import.meta.hot)
  );
}
