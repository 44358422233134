import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import type { SearchData } from '@/api/types/deepbox/search';
import { deepBoxSearchAPI } from '@/api/deepbox/search';

interface SearchParamsPayload {
  nodeId?: string;
  query?: string;
  offset?: number;
  limit?: number;
  t?: string;
}

export const useDeepBoxSearchStore = defineStore('deepBoxSearch', () => {
  const fetchSearchResultsPending = ref(false);
  const searchResults = ref<SearchData | undefined>(undefined);
  const lastSearchParamsPayload = ref<SearchParamsPayload | undefined>(
    undefined
  );

  async function fetchSearchResults(payload: {
    nodeId: string;
    query?: string;
    offset?: number;
    limit?: number;
    tags?: string;
  }) {
    fetchSearchResultsPending.value = true;
    try {
      lastSearchParamsPayload.value = { ...payload };
      const params = {
        parentNodeId: payload.nodeId,
        q: payload.query,
        offset: payload.offset,
        limit: payload.limit,
        t: payload.tags
      };
      const res = await deepBoxSearchAPI.get(params);
      searchResults.value = { ...res.data };
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      fetchSearchResultsPending.value = false;
    }
  }

  async function fetchNextSearchResults(payload: {
    nodeId: string;
    query?: string;
    offset?: number;
    limit?: number;
    tags?: string;
  }) {
    fetchSearchResultsPending.value = true;
    try {
      lastSearchParamsPayload.value = { ...payload };
      const params = {
        parentNodeId: payload.nodeId,
        q: payload.query,
        offset: payload.offset,
        limit: payload.limit,
        t: payload.tags
      };
      const res = await deepBoxSearchAPI.get(params);
      const searchResultsItems = searchResults.value?.items || [];
      searchResults.value = {
        ...res.data,
        items: [...searchResultsItems, ...res.data.items]
      };

      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      fetchSearchResultsPending.value = false;
    }
  }

  async function refresh() {
    if (lastSearchParamsPayload.value) {
      await fetchSearchResults(lastSearchParamsPayload.value);
    }
  }

  return {
    // state
    lastSearchParamsPayload,
    fetchSearchResultsPending,
    searchResults,
    // actions
    fetchSearchResults,
    fetchNextSearchResults,
    refresh
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxSearchStore, import.meta.hot)
  );
}
