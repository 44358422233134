import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import type { Node } from '@/api/types/deepbox/node.ts';
import type { PathSegment } from '@/api/types/deepbox/path.ts';
import type { SearchItem } from '@/api/types/deepbox/search.ts';

export const useDragDropStore = defineStore('drag-drop', () => {
  const nodesBeingDragged = ref(false);
  const nodesBeingDragUploaded = ref(false);
  // target node for upload or move operations
  const targetNode = ref<Node | PathSegment | SearchItem>();

  return {
    nodesBeingDragged,
    nodesBeingDragUploaded,
    targetNode
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDragDropStore, import.meta.hot));
}
